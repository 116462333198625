import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Footer from "../footer/footer";
import {
  requestCounts,
  topPerformers,
  latestWasteCollected,
  getTotalWasteQuantity,
  segregatedWaste,
  topPerformersGroups
} from "../../Services/api";
import moment from "moment-timezone";
import { DatePicker } from "antd";

import "antd/dist/antd.min.css";
import DoughnutData from "./DoughnutData";
import OccupantDetailChart from "./OccupantDetailChart";
import WasteCollectedAreaChart from "./WasteCollectedAreaChart";
import { Set, Get } from "../../Helpers/localStorage";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-spinner-material';
import { manageSegregatedList } from "../../Helpers/customMethod";
import "./dashboard.css";

const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;
const Dashboard = () => {
  const [request, setRequest] = useState(null);
  const [segregatedData, setSegregatedData] = useState([]);
  const [segregatedType, setSegregatedType] = useState("WEEK");
  const [allTopPerformers, setTopPerformers] = useState([]);
  const [allTopGroups, setTopGroups] = useState([]);
  const [allWastageCollection, setWastageCollection] = useState([]);
  const [wasteQty, setWasteQty] = useState({
    thisMonth: 0,
    lastMonth: 0,
    allTime: 0,
  });
  const [occDetails, setOccDetails] = useState({
    acceptedByCollector: 0,
    acceptedThroughYouthGroup: 0,
    totalRequestCount: 0,
  });
  const [startDate, setStartDate] = useState(
    moment().subtract(15, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [Session, setSession] = useState(true);
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [chartSpinner, setChartSpinner] = useState(false);
  const [latestSpin, SetLatestSpin] = useState(false);
  const [topPerformersSpin, setTopPerformersSpin] = useState(false);
  const [topPerformersGroupSpin, setTopPerformersGroupSpin] = useState(false);
  const [wasteQuantitySpin, setWasteQuantitySpin] = useState(false);

  useEffect(() => {
    setSession(Get(Storage.ADMIN));
    if (!Session) navigate('/', { replace: true });
    totalRequests();
    wasteCollectedList();
    topPerformersList();
    topPerformersGroupList();
    getWasteQuantity();
    getSegregatedWaste("WEEK");
  }, []);

  const totalRequests = async () => {
    setSpinner(true);
    const listing = await requestCounts({ startDate, endDate });
    if (listing.code == 401) {
      navigate('/')
    }
    setRequest(listing.result);
    setSpinner(false);
  };

  const getWasteQuantity = async () => {
    setWasteQuantitySpin(true);
    const wasteQty = await getTotalWasteQuantity({ startDate, endDate });
    if (wasteQty.code == 401) {
      navigate('/')
    }
    setWasteQty(wasteQty.result);
    setWasteQuantitySpin(false);
  }

  const wasteCollectedList = async () => {
    SetLatestSpin(true);
    const wastageCollection = await latestWasteCollected();
    if (wastageCollection.code == 401) {
      navigate('/')
    }
    console.log("latestWasteCollected : ", wastageCollection.result);
    setWastageCollection(wastageCollection.result);
    SetLatestSpin(false);
  }

  const topPerformersList = async () => {
    setTopPerformersSpin(true)
    const topPerformer = await topPerformers();
    if (topPerformer.code == 401) {
      navigate('/')
    }
    setTopPerformers(topPerformer.result);
    setTopPerformersSpin(false)
  }

  const topPerformersGroupList = async () => {
    setTopPerformersGroupSpin(true)
    const topPerformer = await topPerformersGroups();
    if (topPerformer.code == 401) {
      navigate('/')
    }
   
    setTopGroups(topPerformer.result);
    setTopPerformersGroupSpin(false)
  }

  const jobStatus = (status) => {
    if (status === "PENDING") {
      return <div className="pending-status">Pending</div>;
    } else if (status === "COMPLETED") {
      return <div className="completed-status">Complete</div>;
    } else if (status === "REQUESTED") {
      return <div className="requested-status">Requested</div>;
    } else if (status === "CANCELLED") {
      return <div className="cancelled-status">Cancelled</div>;
    }
    // <div className="pending-status">Pending</div>
  };
  const getSegregatedWaste = async (val) => {
    setChartSpinner(true);
    let segregatedList = await segregatedWaste({ filterType: val });
    if (segregatedList.code == 401) {
      navigate('/')
    }
    if (segregatedList.code == 200) {
      let listedData = await manageSegregatedList({ type: val, list: segregatedList.result });
      setSegregatedData(listedData);
    }
    setChartSpinner(false);
  }
  const changeButton = async (val) => {
    if (val) {
      setSegregatedType(val)
      await getSegregatedWaste(val)
    }
  }
  const getTimeValues = async (s, e) => {
    if(s){
      setStartDate(moment(s[0]).format("YYYY-MM-DD"));
      setEndDate(moment(s[1]).format("YYYY-MM-DD"))
    }
    
  }
  useEffect(() => {
    totalRequests();
    getWasteQuantity();
  }, [startDate, endDate])


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label xaxis-label">{label}</p>
          <p className="label" style={{ color: `${payload[0].fill}` }}>{`Plastic : ${payload[0].value} kg`}</p>
          <p className="label" style={{ color: `${payload[1].fill}` }}>{`Organic : ${payload[1].value} kg`}</p>
          <p className="label" style={{ color: `${payload[2].fill}` }}>{`Mixed : ${payload[2].value} kg`}</p>
          <p className="label" style={{ color: `${payload[3].fill}` }}>{`Metal : ${payload[3].value} kg`}</p>
        </div>
      );
    }

    return null;
  };
  const segregatedWasteHandler = async (type, name) =>{
    navigate(`/collection-request?duration=${type.name}&period=${segregatedType}&name=${name}`)
  }
  const detailPage = (id) => {navigate(`/collection-details/:${id}`) };
  return (
    <>
      <div className="dashboard">
        <div className="d-flex justify-content-between align-items-start">
          <div className="heading">
            <h1>Dashboard</h1>
            <p>Welcome to the Freetown waste management</p>
          </div>

          <div className="d-flex align-items-center">
            {/* <Select
              className="mx-2"
              showSearch
              placeholder="City"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            /> */}
            {/* <Select
              className="mx-2"
              showSearch
              placeholder="Area"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            /> */}
            <RangePicker
              className="ms-2"
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={(s, e) => getTimeValues(s, e)}
            />
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="card-container">
              <div className="d-flex justify-content-between align-items-center">
                <div className="refresh-icon me-4" onClick={() => totalRequests()}>
                  <i className={"fa fa-refresh " + (spinner === true ? "fa-spin" : "")} ></i>
                </div>
                <div className="filter-icon">
                  <i className="fa fa-filter"></i>
                </div>
              </div>
              <div className="graph my-3 full-circular-graph">
                <div className="graph-detail d-flex justify-content-end">
                  {/* <div className="graph-range me-2">20.8%</div>
                  <div className="bottom-arrow-left">
                    <img
                      src="images/arrow-bottom-left.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div> */}
                </div>
                <div style={{ width: 140, height: 140, margin: "auto", cursor: "pointer" }} onClick={() => { navigate('/collection-request') }} >
                  <h6 className="text-center">
                    Total requests <br /> <span>{request?.totalReq}</span>
                  </h6>
                  <CircularProgressbar
                    value={request?.totalReq}
                    text=""
                    styles={buildStyles({
                      textSize: "10px",
                      pathTransitionDuration: 0.5,
                      textColor: "#000",
                    })}
                  />
                </div>
              </div>
              <div className="graph-details d-flex justify-content-evenly">
                <div className="current-month text-center">
                  <p className="mb-2">{request?.totalReqCurrentMonth}</p>
                  <h6>Current month</h6>
                </div>
                <div className="last-month text-center">
                  <p className="mb-2">{request?.totalReqLastMonth}</p>
                  <h6>Last month</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="card-container height-auto mb-4">
                  <div className="graph-details d-flex justify-content-between align-items-end" style={{ cursor: "pointer" }} onClick={() => { navigate(`/collection-request?status=PENDING&start=${startDate}&end=${endDate}`) }}>
                    <div className="pending-request">
                      <img src="images/loader-icon.png" alt="" className="mb-3" />
                      <h6 className="mb-4">Pending request</h6>
                    </div>
                    <div className="pending-request-graph d-flex flex-column align-items-end">
                      <div className="graph mb-5 pb-2">
                        <div style={{ width: 36, height: 36, margin: "auto", cursor: "pointer" }} onClick={() => { navigate(`/collection-request?status=PENDING&start=${startDate}&end=${endDate}`) }}>
                          <h4 className="mb-0 text-center">{request?.pendingReq}</h4>
                          <CircularProgressbar
                            value={request?.pendingReq}
                            strokeWidth={12}
                          />
                        </div>
                      </div>
                      <h6 className="mb-0">
                        {/* <span className="green-color">
                      <img
                        src="images/upper-right-arrow.svg"
                        alt=""
                        className="img-fluid me-2"
                      />{" "}
                      30%
                    </span>{" "}
                    than Last Month */}
                      </h6>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-6">
                <div className="card-container height-auto mb-4">
                  <div className="graph-details d-flex justify-content-between align-items-end" style={{ cursor: "pointer" }} onClick={() => { navigate(`/collection-request?status=TRANSIT&start=${startDate}&end=${endDate}`) }}>
                    <div className="pending-request">
                      <img src="images/loader-icon.png" alt="" className="mb-3" />
                      <h6 className="mb-4">InProcess request</h6>
                    </div>
                    <div className="pending-request-graph d-flex flex-column align-items-end">
                      <div className="graph mb-5 pb-2">
                        <div style={{ width: 36, height: 36, margin: "auto" }}>
                          <h4 className="mb-0 text-center">{request?.inProcess}</h4>
                          <CircularProgressbar
                            value={request?.inProcess}
                            strokeWidth={12}
                          />
                        </div>
                      </div>
                      <h6 className="mb-0">
                      </h6>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-12">
                <div className="card-container height-auto">
                  <div className="graph-details d-flex justify-content-between align-items-end my-3" style={{ cursor: "pointer" }} onClick={() => { navigate(`/collection-request?status=COMPLETED&start=${startDate}&end=${endDate}`) }}>
                    <div className="pending-request">
                      <img src="images/check.svg" alt="" className="mb-3" />
                      <h6 className="mb-4">Completed request</h6>
                    </div>
                    <div className="pending-request-graph d-flex flex-column align-items-end">
                      <div className="graph mb-5 pb-2">
                        <div style={{ width: 36, height: 36, margin: "auto" }}>
                          <h4 className="mb-0">{request?.completedReq}</h4>
                          <CircularProgressbar
                            value={request?.completedReq}
                            strokeWidth={12}
                          />
                        </div>
                      </div>
                      <h6 className="mb-0">
                        {/* <span className="red-color">
                      <img
                        src="images/arrow-bottom-left.svg"
                        alt=""
                        className="img-fluid me-2"
                      />{" "}
                      15%
                    </span>{" "}
                    than Last Month */}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card-container">
              <div className="d-flex justify-content-between">
                <div className="refresh-icon me-4" onClick={() => getWasteQuantity()}>
                  <i className={"fa fa-refresh " + (wasteQuantitySpin === true ? "fa-spin" : "")}></i>
                </div>
                <div className="filter-icon">
                  <i className="fa fa-filter"></i>
                </div>
              </div>
              <div className="semi-donut-graph graph my-3">
                <div className="graph-detail d-flex justify-content-end">
                  {/* <div className="graph-range me-2">20.8%</div>
                  <div className="bottom-arrow-left">
                    <img
                      src="images/up-arrow-left-green.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div> */}
                </div>
                <div className="semi-donut margin text-center" style={{ cursor: "pointer" }} onClick={() => { navigate('/collection-request') }}>
                  <span>
                    Total waste <br /> Collected
                  </span>{" "}
                  {wasteQty?.allTime} Kg
                </div>
              </div>
              <div className="graph-details d-flex justify-content-evenly semi-donut-details">
                <div className="current-month text-center">
                  <p className="mb-2">{wasteQty?.thisMonth}</p>
                  <h6>Current month</h6>
                </div>
                <div className="last-month text-center">
                  <p className="mb-2">{wasteQty?.lastMonth}</p>
                  <h6>Last month</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="card-container p-0">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h5 className="p-4 mb-0">Segregated Waste</h5>
                <div className="d-flex align-items-center">
                  <div className="buttons days-buttons me-3">
                    {/* <button className="mx-1 active" onClick={()=>changeSegregatedType("WEEK")}>Day</button> */}
                    <button className={"mx-1 " + (segregatedType === "WEEK" ? "active" : "")} onClick={() => changeButton("WEEK")}>Week</button>
                    <button className={"mx-1 " + (segregatedType === "MONTH" ? "active" : "")} onClick={() => changeButton("MONTH")}>Month</button>
                    <button className={"mx-1 " + (segregatedType === "YEAR" ? "active" : "")} onClick={() => changeButton("YEAR")}>Year</button>
                  </div>
                  <div className="refresh-icon mx-2" onClick={() => changeButton("WEEK")}>
                    <i className={"fa fa-refresh " + (chartSpinner === true ? "fa-spin" : "")}></i>
                  </div>
                  {/* <div className="three-dots ms-1 me-4">
                    <i className="fa fa-ellipsis-v"></i>
                  </div> */}
                </div>
              </div>

              <div className="graph">
                <ComposedChart
                  width={760}
                  height={350}
                  data={segregatedData}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  {/* <YAxis /> */}
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />

                  <Bar dataKey="Plastic" onClick={(e)=>{segregatedWasteHandler(e, 'Plastic')}} stackId="a" barSize={24} fill="#f26128" />
                  <Bar dataKey="Organic" onClick={(e)=>{segregatedWasteHandler(e, 'Organic')}} stackId="a" barSize={10} fill="#e8ce46" />
                  <Bar dataKey="Mixed" onClick={(e)=>{segregatedWasteHandler(e, 'Mixed')}} stackId="a" barSize={10} fill="#00b6d6" />
                  <Bar dataKey="Metal" onClick={(e)=>{segregatedWasteHandler(e, 'Metal')}} stackId="a" barSize={10} fill="#97c950" />
                  {/* <Line type="monotone" dataKey="Plastic" stroke="#97c950" /> */}
                </ComposedChart>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <WasteCollectedAreaChart />
          </div>
          {/* <div className="col-lg-4">
            <div className="card-container">
              <div className="graph-heading d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="buttons days-buttons me-3">
                    <button className="mx-1 active">Day</button>
                    <button className="mx-1">Week</button>
                    <button className="mx-1">Month</button>
                  </div>
                  <div className="refresh-icon mx-1">
                    <i className="fa fa-refresh"></i>
                  </div>
                </div>
              </div>
              <div className="graph">
                <div className="doughnut-chart">
                  <DoughnutData />
                </div>
              </div>
              <h6 className="text-center">Waste Collection Requests</h6>
            </div>
          </div> */}
          <div className="col-lg-4">
            <OccupantDetailChart />
          </div>
          <div className="col-lg-8">
            <div className="card-container p-0">
              <div className="card-heading p-4 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Latest Waste Collected</h5>
                <div className="d-flex align-items-center">
                  <div className="refresh-icon me-2" onClick={() => wasteCollectedList()}>
                    <i className={"fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")}></i>
                  </div>
                  {/* <div className="three-dots">
                    <i className="fa fa-ellipsis-v"></i>
                  </div> */}
                </div>
              </div>
              <div className="card-table table-responsive">
                <table className="w-100">
                  <tbody>
                    <tr className="sticky-top">
                      <th>#Ticket No</th>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Area</th>
                      <th>Status</th>
                    </tr>
                    {allWastageCollection.map((data) => {
                      return (
                        <>
                          <tr className="detail-link" key={data._id.toString()} onClick={() => detailPage(data._id)}>
                            <td>{data._id.slice(0, 10)}</td>
                            <td>
                              {moment(data.startDate).format("YYYY/MM/DD")}
                            </td>
                            <td>
                              {data.userId ? data?.userId?.userName : "N/A"}
                            </td>
                            <td>{data.location.city ? data.location.city : "freetown"}</td>
                            <td>{jobStatus(data.status)}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {!allWastageCollection.length ? <div style={{ textAlign: 'center', color: 'red' }}>No data found!</div> : ""}
              </div>
            </div>
          </div>
          {/* 
          <div className="col-lg-12">
            <div className="card-container p-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="card-heading">
                  <h5 className="p-4">Top Performers Collectors</h5>
                </div>
                <div className="refresh-icon me-4" onClick={() => topPerformersList()}>
                  <i className={"fa fa-refresh " + (topPerformersSpin === true ? "fa-spin" : "")}></i>
                </div>
              </div>
              <div className="card-table table-responsive">
                <table className="w-100">
                  <tbody>
                    <tr className="sticky-top">
                      <th>Collector Name</th>
                      <th>Area</th>
                      <th>Daily Requests Attended</th>
                    </tr>

                    {allTopPerformers.map((data, index) => {
                      return (
                        <>
                          <tr key={index.toString()}>
                            <td>{data?.userName}</td>
                            <td>{data?.address ? data.address : "N/A"}</td>
                            <td>{data?.totalJobCompleted}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {!allTopPerformers.length ? <div style={{ textAlign: 'center', color: 'red' }}>No data found!</div> : ""}

              </div>
            </div>
          </div> */}

          <div className="col-lg-12">
            <div className="card-container p-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="card-heading">
                  <h5 className="p-4">Top Performers Youth Groups</h5>
                </div>
                <div className="refresh-icon me-4" onClick={() => topPerformersGroupList()}>
                  <i className={"fa fa-refresh " + (topPerformersSpin === true ? "fa-spin" : "")}></i>
                </div>
              </div>
              <div className="card-table table-responsive">
                <table className="w-100">
                  <tbody>
                    <tr className="sticky-top">
                      <th>Youth Group</th>
                      <th>Area</th>
                      <th>Daily Requests Attended</th>
                    </tr>

                    {allTopGroups.map((data, index) => {
                      return (
                        <>
                          <tr className={` 
                            ${data.block == 'BLOCK ONE'?'block-one':''}
                            ${data.block == 'BLOCK TWO'?'block-two':''}
                            ${data.block == 'BLOCK THREE'?'block-three':''}
                            ${data.block == 'BLOCK FOUR'?'block-four':''}
                            ${data.block == 'BLOCK FIVE'?'block-five':''}
                            ${data.block == 'BLOCK SIX'?'block-six':''}
                            ${data.block == 'BLOCK SEVEN'?'block-seven':''}
                            ${data.block == 'BLOCK EIGHT'?'block-eight':''}
                          `}
                           key={index.toString()}>
                            <td>{data?.name}</td>
                            <td>{data?.location.address ? data.location.address : "N/A"}</td>
                            <td>{data?.totalJobCompleted}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {!allTopPerformers.length ? <div style={{ textAlign: 'center', color: 'red' }}>No data found!</div> : ""}

              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
