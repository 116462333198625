import { Urls } from '../Utils/apiUrl';
import { EndPoints } from '../Utils/endPoint';
import { Get } from '../Helpers/localStorage';
import axios from 'axios';



export const login = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.login, data).then(async response => {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}




export const getUsers = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.userList,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const getUserDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.userDetails,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}




export const getCollectors = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.collectorsList,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const getCollectorDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.collectorDetails,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const nearbyCollectorsList = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.nearbyCollectorsList,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}




export const createGroup = async (data) => {
    console.log(data);
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.createGroup,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const createCollectors = async (data) => {
    console.log(data);
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.createCollectors,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const updateCollectorDetails = async (data) => {
    console.log(data);
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.updateCollector,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const deleteCollector = async (data) => {
    console.log(data);
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.deleteCollector,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const deleteYouth = async (data) => {
    console.log(data);
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.deleteGroup,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const changeYouthGroupStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.updateYouthGroupStatus,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export const sendCredentials = async (data) => {
    console.log(data);
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.sendCredentials,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const editYouthGroup = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.editYouthGroup,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const youthGroup = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.youthGroup,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const requestCounts = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.requestCounts,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const occupantsDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.occupantsDetails,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const chartDataByCities = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.recordByCities,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const getTotalWasteQuantity = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.getTotalWasteQuantity,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const topPerformers = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.topPerformers,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const topPerformersGroups = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.topPerformersYouthGroups,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const feedBackList = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.feedBacks,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const latestWasteCollected = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.latestWasteCollected,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const changeUserStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.changeUserStatus,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const collectionRequest = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.collectionRequest,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const collectionDetail = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.serviceDetails,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const changeCollectorStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.changeCollectorStatus,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const changePassword = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.changePassword,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}




export const getProfile = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.getProfile,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const editProfile = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.editProfile,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const getWastePrice = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.getWastePrice,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const createWastePrice = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.createWastePrice,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const editWastePrice = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.editWastePrice,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const changeWastePriceStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.changeWastePriceStatus,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const removeWasteVolume = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.removeWasteVolume,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        })
    })
}


export const transactions = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.transactions,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const transactionDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.transactionDetail,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const segregatedWaste = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.segregatedWaste,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const deleteAccountOtp = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.deleteAccountSendOtp,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export const deleteAccount = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.deleteAccount,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const wasteVolumes = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.wasteVolumes,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const updateCollections = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.serviceupdate,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getTickets = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.ticketsList,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export const ticketStatusUpdate = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.ticketUpdate,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getTicketDetails = async (id) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.ticketDetails + id,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const ticketSendMsg = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.ticketSendMsg,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getTicketReceiveMsg = async (id) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(Urls.baseUrl + EndPoints.ticketReceiveMsg + id,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('Admin')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}