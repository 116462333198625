import { useState, useEffect } from "react";
import LoginComponent from "../Components/app/login";
import Dashboard from "../Components/app/dashboard";
import UserList from "../Components/app/userList";
import UserDetails from "../Components/app/userDetails";
import Collector from "../Components/app/collector";
import YouthGroup from "../Components/app/youthGroupManagement";
import Settings from "../Components/app/settings";
import CollectorDetails from "../Components/app/collectorDetails";
import CollectionRequest from "../Components/app/CollectionRequest";
import CollectionDetails from "../Components/app/collectionDetails";
import ChangePassword from "../Components/app/changePassword";
import AccountInfo from "../Components/app/accountInfo";
import PriceManagement from "../Components/app/priceManagement";
import TransactionList from "../Components/app/transactionList";
import CityList from "../Components/app/cityList";
import { useLocation, Routes, Route, useNavigate } from "react-router-dom";
import Layout from "../Components/sideMenu/navSidebar";
import HeadersTop from "../Components/headers/HeadersTop";
import { Get } from "../Helpers/localStorage";
import { Storage } from "../Helpers/appResource";
import UserFeedbacks from "../Components/app/userFeedbacks";
import DeleteAccount from "../Components/app/deleteAccount";
import WasteVolume from "../Components/app/WasteVolume";
import Tickets from "../Components/app/tickets";
import TicketDetail from "../Components/app/ticketDetail";
const AppRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [toggleSideBar, setToggleSideBar] = useState(false)
    const [Session, setSession] = useState(true);

    const stateSideBar = () => {
        setToggleSideBar(!toggleSideBar);
    }
    useEffect(() => {
        setSession(Get(Storage.ADMIN));

        if (!Session) navigate('/', { replace: true });
    }, [location])
    return (
        <>
            {Session ? (<>
                <Layout toggleSideBar={toggleSideBar} />
                <HeadersTop stateSideBar={stateSideBar} />
            </>) : ""}
            <Routes >
                <Route exact path="/" element={<LoginComponent />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/userList" element={<UserList />} />
                <Route exact path="/userDetails/:id" element={<UserDetails />} />
                <Route exact path="/collector" element={<Collector />} />
                <Route exact path="/youthGroupManagement" element={<YouthGroup />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/changePassword" element={<ChangePassword />} />
                <Route exact path="/accountInfo" element={<AccountInfo />} />
                <Route exact path="/collectorDetails/:id" element={<CollectorDetails />} />
                <Route exact path="/cityList" element={<CityList />} />
                <Route exact path="/collection-details/:id" element={<CollectionDetails />} />
                <Route exact path="/priceManagement" element={<PriceManagement />} />
                <Route exact path="/transaction" element={<TransactionList />} />
                <Route exact path="/collection-request" element={<CollectionRequest />} />
                <Route exact path="/collection-details/:id" element={<CollectionDetails />} />
                <Route exact path="/priceManagement" element={<PriceManagement />} />
                <Route exact path="/transaction" element={<TransactionList />} />
                <Route exact path="/usersFeedback" element={<UserFeedbacks />} />
                <Route exact path="/tickets" element={<Tickets />} />
                <Route exact path="/ticket/:id" element={<TicketDetail />} />
                <Route exact path="/wasteVolume" element={<WasteVolume />} />
                <Route exact path="/deleteAccount" element={<DeleteAccount />} />
            </Routes>
        </>
    );
};

export default AppRoutes;