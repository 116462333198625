
export const EndPoints = {
    login: "admin/login",
    userList: "admin/users",
    userDetails: "admin/UserDetail",
    serviceDetails: "admin/serviceDetail",
    serviceupdate: "admin/serviceupdate",
    collectorsList: "admin/collectors",
    collectorDetails: "admin/collectorDetail",
    createGroup: "admin/createYouthGroup",
    createCollectors: "admin/createCollector",
    updateCollector: "admin/updateCollector",
    deleteCollector: "admin/deleteCollector",
    deleteGroup: "admin/deleteYouthGroup",
    updateYouthGroupStatus: "admin/updateYouthGroupStatus",
    sendCredentials: "admin/collectorCredentials",
    nearbyCollectorsList: "admin/getNearbyCollectors",
    youthGroup: "admin/youthGroups",
    editYouthGroup: "admin/addCollectorsToGroup",
    requestCounts: "admin/requestCounts",
    topPerformers: "admin/topPerformers",
    topPerformersYouthGroups: "admin/topPerformersGroups",
    latestWasteCollected: "admin/latestWasteCollected",
    changeUserStatus: "admin/changeUserStatus",
    collectionRequest: "admin/getServices",
    changeCollectorStatus: "admin/changeCollectorStatus",
    changePassword: "admin/changePassword",
    getProfile: "admin/me",
    editProfile: "admin/editProfile",
    getWastePrice: "admin/getWastePrice",
    createWastePrice: "admin/createWastePrice",
    editWastePrice: "admin/editWastePrice",
    changeWastePriceStatus: "admin/changeWastePriceStatus",
    removeWasteVolume: "admin/removeWasteVolume",
    transactions: "admin/transactions",
    transactionDetail: "admin/transactionDetails",
    getTotalWasteQuantity: "admin/getTotalWasteQuantity",
    occupantsDetails: "admin/occupantsDetails",
    recordByCities: "admin/recordByCity",
    segregatedWaste: "admin/RecordByType",
    feedBacks: "admin/feedbackList",
    deleteAccountSendOtp: "admin/deleteAccountSendOtp",
    deleteAccount: "admin/deleteAccount",
    wasteVolumes: "admin/wasteVolumes",
    ticketsList: "ticket/list",
    ticketUpdate: "ticket/update",
    ticketDetails: "ticket/details/",
    ticketSendMsg: "ticket/sendMsg",
    ticketReceiveMsg: "ticket/receiveMsgs/" 
}