/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";


const HeadersTop = (props) => {
  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="logo d-flex align-items-center">
                    <Link to="/">
                      <img
                        src="images/logo-dorty.png"
                        alt="logo"
                        className="img-fluid me-5"
                      />
                    </Link>
                  </div>
                  <div
                    className="hamburger-icon me-4 d-flex align-items-center justify-content-center" onClick={props.stateSideBar}
                  >
                    <i className="fa fa-bars"></i>
                  </div>
                  {/* <div className="search-icon d-flex justify-content-center align-items-center">
                    <i className="fa fa-search"></i>
                  </div> */}
                </div>
                {/* <div className="search-bar">
                                    <input type="text" placeholder='Search' className='search-box' />
                                </div> */}
                                <div className="dahboard-name">
                                  <h4 className="mb-0">DortiBox Administrator</h4>
                                </div>
                <div className="right-side d-flex align-items-center">
                  {/* <div className="notification me-3 pe-3 position-relative">
                    <i className="fa fa-bell"></i>
                    <div className="badges"></div>
                  </div> */}
                  <div className="user-name">
                    {" "}
                    Admin{" "}
                    <img src="images/user.png" className="img-fluid ms-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadersTop;
