import AppRoutes from './AppRoutes';
import {
  BrowserRouter
} from "react-router-dom";
import "@material/react-dialog/dist/dialog.css";

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div className="wrapper">
          <AppRoutes />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
