import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Doughnut } from "react-chartjs-2";
import { chartDataByCities } from "../../Services/api";


ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  layout: {
    padding: 10,
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",

      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};


const WasteCollectedAreaChart = () => {
  const [cities, setCities] = useState([]);
  const [counts, setCounts] = useState([]);
  const [latestSpin, setLatestSpin] = useState(false);
  const [filterType, setFilterType] = useState("Today");



  useEffect(() => {
    citiesData();
  }, [])

  const citiesData = async () => {
    setLatestSpin(true);
    setCities([]);
    setCounts([]);
    let tempLabel = [];
    let tempCounts = [];
    const data = await chartDataByCities({ type: filterType })
    data.result.forEach(element => {
      tempLabel.push(element._id);
      tempCounts.push(element.count);
    });
    setCities(tempLabel);
    setCounts(tempCounts);
    setLatestSpin(false);
  }
  useEffect(() => {
    citiesData()
  }, [filterType])


  var chartData = {
    labels: cities,
    datasets: [
      {
        label: "# of Votes",
        data: counts,
        backgroundColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "rgba(242, 97, 34, 1)",
        ],
        borderColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "rgba(242, 97, 34, 1)",
        ],
        hoverOffset: 20,
        borderWidth: 2,
      },
    ],
  };
  const redirectHandler = async (e, item) =>{
    console.log("working : ", item);
  }
  return (
    <>
      <div className="card-container">
        <div className="graph-heading d-flex align-items-center justify-content-between">
          <div className="refresh-icon ms-1" onClick={() => citiesData()}>
            <i className={"fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")}></i>
          </div>
          <div className="buttons days-buttons me-3">
            {/* <button className="mx-1 active" onClick={()=>changeSegregatedType("WEEK")}>Day</button> */}
            <button className={"mx-1 " + (filterType === "Today" ? "active" : "")} onClick={() => setFilterType("Today")}>Today</button>
            <button className={"mx-1 " + (filterType === "Week" ? "active" : "")} onClick={() => setFilterType("Week")}>Week</button>
            <button className={"mx-1 " + (filterType === "Month" ? "active" : "")} onClick={() => setFilterType("Month")}>Month</button>
          </div>
        </div>
        <div className="graph waste-collected-area-chart">
          {counts.length ? <Doughnut data={chartData} options={{options}} onClick={(e, item)=>{
            console.log("source", e.active);
          }} /> : <div style={{ height: 300, width: 300 }} className="d-flex align-items-center justify-content-center"><h5 className="text-danger">No Requests for {filterType}</h5></div>}

        </div>
        <h6 className="text-center">Waste Collection requests</h6>
      </div>
    </>
  );
};

export default WasteCollectedAreaChart;
