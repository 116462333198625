/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { getUserDetails } from '../../Services/api';
import { toast } from 'react-toastify';
import "../../style/css/style.css"
import Spinner from 'react-spinner-material';
import TabsWrap from './common/TabsWrap';

const UserDetails = () => {
    let navigate = useNavigate();
    const [user, setDetails] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const dataFetchedRef = useRef(false);
    const userTabs = ["REQUEST", "TRANSACTIONS"];

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        userDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const projectId = window.location.pathname.split(':');
    if (!projectId[1]) return navigate('/userList', { replace: true });

    async function userDetails() {
        setSpinner(true);
        const details = await getUserDetails({ "userId": projectId[1] });
        if (details.code == 401) {
            navigate('/');
        }
        setSpinner(false);
        // eslint-disable-next-line eqeqeq
        details.code == 200 ? setDetails(details.result) : toast('User not found!')
    }



    return (
        <>
            <div className="dashboard content-wrapper">
                <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mb-4">

                                <div className="d-flex justify-content-between">
                                    <div className='d-flex align-items-center'>
                                        <a className="submit me-3 point" onClick={() => navigate('/userList')} ><i className='fa fa-long-arrow-left'></i></a> <h4>User Details</h4>
                                    </div>

                                </div>
                            </div>
                            <div className="user-details">
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-12">
                                        <div className="user-img">{
                                            (user.profileImage ? <img src={user?.profileImage} alt="" className='img-fluid' /> : <img src="/images/user.png" alt="" className='img-fluid' />

                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Phone Number :</p> </div>
                                    <div className="col-sm-8">
                                        <h6>{user.countryCode}{user.mobile}</h6>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Email-ID :</p>
                                    </div>
                                    <div className="col-sm-8">
                                        <h6>{user.email ? user.email : 'N/A'}</h6>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Address :</p>
                                    </div>
                                    <div className="col-sm-8">
                                        <h6>{user?.location ? user.location.address : 'N/A'}</h6>
                                    </div>
                                </div>
                            </div>
                            <TabsWrap type="USER" tabs={userTabs} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default UserDetails;