import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { occupantsDetails } from "../../Services/api";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  layout: {
    padding: 10,
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",

      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};


const OccupantDetailChart = () => {
  const [record, setRecord] = useState([0, 0, 0])
  const [latestSpin, setLatestSpin] = useState(false)

  useEffect(() => {
    occupantsDetail();
  }, [])

  const occupantsDetail = async () => {
    setLatestSpin(true)
    let temp = []
    let res = await occupantsDetails();
    temp.push(res.result.users);
    temp.push(res.result.collectors);
    temp.push(res.result.youthGroups);
    setRecord(temp)
    setLatestSpin(false)
  }

  var chartData = {
    labels: ["Users", "Collectors", "Youth Groups"],
    datasets: [
      {
        label: "# of Votes",
        data: record,
        backgroundColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "rgba(242, 97, 34, 1)",
        ],
        borderColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "rgba(242, 97, 34, 1)",
        ],
        hoverOffset: 20,
        borderWidth: 2,
      },
    ],
  };


  return (
    <>
      <div className="card-container">
        <div className="graph-heading d-flex align-items-center justify-content-between">
          <h5 className="mb-0 text-start">Occupants Details</h5>
          <div className="d-flex align-items-center">
            <div className="refresh-icon me-2" onClick={() => occupantsDetail()}>
              <i className={"fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")}></i>
            </div>
            {/* <div className="three-dots">
                    <i className="fa fa-ellipsis-v"></i>
                  </div> */}
          </div>
        </div>
        <div className="graph occupant-detail-graph">
          <Pie data={chartData} options={options} />
        </div>
      </div>

    </>
  );
};

export default OccupantDetailChart;
