/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { collectionDetail, updateCollections } from '../../Services/api';
import { toast } from 'react-toastify';
import "../../style/css/style.css"
import Spinner from 'react-spinner-material';
import Modal from 'react-bootstrap/Modal';


const CollectionDetails = () => {
    let navigate = useNavigate();
    const [user, setDetails] = useState([]);
    const [spinner, setSpinner] = useState(false);
    
    const [show, setShow] = useState(false);
    const [recurring, setrecurring] = useState(user?.isRecurring);
    const [quantity, setQuantity] = useState(user?.wasteQuantity);
    const [requestStatus, setRequestStatus] = useState(user?.requestStatus);
    const [status, setStatus] = useState(user?.status);
    const [paymentStatus, setPaymentStatus] = useState(user?.paymentStatus);
    const [paymentMethod, setPaymentMethod] = useState(user?.paymentMethod);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        collectionDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const projectId = window.location.pathname.split(':');
    if (!projectId[1]) return navigate('/collection-request', { replace: true });

    async function collectionDetails() {
        setSpinner(true);
        const details = await collectionDetail({ "serviceId": projectId[1] });
        if (details.code == 401) {
            navigate("/")
        }
        setSpinner(false);
        // eslint-disable-next-line eqeqeq
        details.code == 200 ? setDetails(details.result) : toast('Collection not found!')

        setRequestStatus(details.result?.requestStatus)
        setStatus(details.result?.status)
        setPaymentStatus(details.result?.paymentStatus)
        setPaymentMethod(details.result?.paymentMethod)
        setrecurring(details.result?.isRecurring)
        setQuantity(details.result?.wasteQuantity)
    }


    const closeEdit = () => { setShow(false); }
    const recurringOnchange = (e)=>{
        setrecurring(e == 'YES'? true : false)
    }
    const updateHandler = async () =>{
        const updateRe = await updateCollections({
            serviceId : projectId[1],
            isRecurring : recurring,
            wasteQuantity : quantity,
            requestStatus : requestStatus,
            status : status,
            paymentStatus : paymentStatus,
            paymentMethod : paymentMethod,
        });
        if (updateRe.code == 401) {
            navigate("/");
        }
        if (updateRe.code === 200) {
            toast.success("Update Successfully.");
            collectionDetails();
            setShow(false);
        } else { toast.error(updateRe.message) }
    }
    return (
        <>
            <div className="dashboard content-wrapper">
                <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mb-4">

                                <div className="d-flex justify-content-between">
                                    <div className='d-flex align-items-center'>
                                        <a className="submit me-3 point" onClick={() => navigate('/collection-request')} ><i className='fa fa-long-arrow-left'></i></a> <h4>Collection Details</h4>
                                        <div title='Edit'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16" >
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="user-details">
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-md-6">
                                        <div className="user-img">{
                                            (user?.userId?.profileImage ? <img src={user?.userId?.profileImage} alt="" className='img-fluid' /> : <img src="/images/user.png" alt="" className='img-fluid' />

                                            )
                                        }
                                        </div>
                                        <div className='d-flex justify-content-center mt-2'>
                                            <h6>User Name :</h6>
                                            <h6 className='ml-3 text-secondary'>{user?.userId?.userName}</h6>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <h6>Email :</h6>
                                            <h6 className='ml-3 text-secondary'>{(user?.userId?.email ? user?.userId?.email : "N/A")}</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="user-img">{
                                            (user?.collectorId?.profileImage ? <img src={user?.collectorId?.profileImage} alt="" className='img-fluid' /> : <img src="/images/user.png" alt="" className='img-fluid' />

                                            )
                                        }
                                        </div>
                                        <div className='d-flex justify-content-center mt-2'>
                                            <h6>Collector Name :</h6>
                                            <h6 className='ml-3 text-secondary'>{user?.collectorId?.userName ? user?.collectorId?.userName : "N/A"}</h6>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <h6>Email :</h6>
                                            <h6 className='ml-3 text-secondary'>{(user?.collectorId?.email ? user?.collectorId?.email : "N/A")}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-md-3'></div>
                                    <div className='col-md-3'>
                                        <h6>ReferenceId :</h6>
                                        <h6>isRecurring :</h6>
                                        <h6>wasteQuantity :</h6>
                                        <h6>requestStatus :</h6>
                                        <h6>status :</h6>
                                        <h6>paymentStatus :</h6>
                                        <h6>paymentMethod :</h6>
                                    </div>
                                    <div className='col-md-3'>
                                        <h6 className='text-secondary'>{user?.referenceId}</h6>
                                        <h6 className='text-secondary'>{(user?.isRecurring ? "YES" : "NO")}</h6>
                                        <h6 className='text-secondary'>{user?.wasteQuantity}</h6>
                                        <h6 className='text-secondary'>{user?.requestStatus}</h6>
                                        <h6 className='text-secondary'>{user?.status}</h6>
                                        <h6 className='text-secondary'>{user?.paymentStatus}</h6>
                                        <h6 className='text-secondary'>{user?.paymentMethod}</h6>
                                    </div>
                                    <div className='col-md-3'>

                                   
                                    </div>
                                    <div className='row mt-5' style={{justifyContent: 'center'}}>
                                    <div style={{display: "inline-flex", width:"150px", cursor: "pointer"}} onClick={()=>{setShow(true)}} >
                                        <svg style={{margin : "7px" }}xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg>
                                        <h5 className="mb-0">  Edit</h5> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id='editGroupModal' className="modal fade" aria-labelledby="addGroupModalLabel" aria-hidden="true">
                <Modal style={{ top: 70 }} show={show} onHide={closeEdit} backdrop="static" keyboard={false}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addGroupModalLabel">Collection Request Edit</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeEdit()}></button>
                        </div>
                        <div className="modal-body editGroupModal">
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">isRecurring</label>
                               
                                <select defaultValue={(recurring ? "YES" : "NO")} onChange={(e)=>{recurringOnchange(e.target.value)}}>
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                    
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">wasteQuantity </label>
                                <input type="text" className="form-control" id="name" onChange={(e)=>{setQuantity(e.target.value)}} value={quantity} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="" className="form-label">requestStatus</label>
                               
                                <select defaultValue={requestStatus} onChange={(e)=>{setRequestStatus(e.target.value)}}>
                                    <option value="PENDING">PENDING</option>
                                    <option value="ACCEPTED">ACCEPTED</option>
                                    <option value="DECLINED">DECLINED</option>
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="" className="form-label">status </label>
                               
                                <select defaultValue={status} onChange={(e)=>{setStatus(e.target.value)}}>
                                    <option value="PENDING" selected={status == 'PENDING'? 'Selected':''}>PENDING</option>
                                    <option value="TRANSIT" selected={status == 'TRANSIT'? 'Selected':''}>TRANSIT</option>
                                    <option value="CANCELLED" selected={status == 'CANCELLED'? 'Selected':''}>CANCELLED</option>
                                    <option value="COMPLETED" selected={status == 'COMPLETED'? 'Selected':''}>COMPLETED</option>
                                    <option value="RESCHEDULED" selected={status == 'RESCHEDULED'? 'Selected':''}>RESCHEDULED</option>
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="" className="form-label">paymentStatus  </label>
                               
                                <select defaultValue={paymentStatus} onChange={(e)=>{setPaymentStatus(e.target.value)}}>
                                    <option value="INITIATED">INITIATED</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="EXPIRED">EXPIRED</option>
                                    <option value="SUCCESS">SUCCESS</option>
                                    <option value="FAILED">FAILED</option>
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">paymentMethod </label>
                                <input type="text" className="form-control" id="name" value={paymentMethod}  onChange={(e)=>{setPaymentMethod(e.target.value)}}/>
                            </div>
                            

                            
                           
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => closeEdit()} data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary d-flex align-items-center" onClick={() => updateHandler()}  >Update</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default CollectionDetails;