/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ticketStatusUpdate, getTickets } from '../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../Helpers/appResource';
import { Set, Get } from '../../Helpers/localStorage';
import Modal from 'react-bootstrap/Modal';
import Dialog, { DialogTitle, DialogContent } from '@material/react-dialog';
import { toast } from 'react-toastify';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import exportFromJSON from 'export-from-json';
import moment from 'moment-timezone';
const Tickets = () => {
    let timerCount = null
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
   
    const [pageNumber, setPageNumber] = useState(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : 1);
    const [search, setSearch] = useState(Get(Storage.SEARCH) ? Get(Storage.SEARCH) : "");
    const [spinner, setSpinner] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [ticketId, setTicketId] = useState(null);
    const [status, setStatus] = useState(true);
    const [dialog, setDialog] = useState("");
    const [buttonSpin, setButtonSpin] = useState(false);
    const [show, setShow] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        ProjectListing();
    }, [pageNumber, search]);

    async function ProjectListing() {
        setSpinner(true);
        setRecordCheck(1);
        const listing = await getTickets({ pageNumber: pageNumber, searchKey: search });
        if (listing.code == 401) {
            navigate('/');
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }

    async function getticketId(id) {
        Set(Storage.PAGE_NUMBER, pageNumber); Set(Storage.SEARCH, search); navigate(`/ticket/${id}`);
    }
    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search); };

    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000)
    }

    const openConfirmDialog = (value, status) => {
        setTicketId(value); setStatus(status === true ? false : true); setIsOpen(true);
        setDialog(<p>Are you sure you want to change status ?</p> );
    };

    const updateStatus = async () => {
        setButtonSpin(true);
        const updateStatus = await ticketStatusUpdate({ id: ticketId, status: status });
        if (updateStatus.code == 401) {
            navigate("/");
        }
        if (updateStatus.code === 200) {
            ProjectListing(); setIsOpen(false); setButtonSpin(false);
            toast.success(Storage.STATUS_SUCCESS);
        } else { toast.error(updateStatus.message); }
    }

    const openNew = () => { setShow(true); }
    const closeNew = () => setShow(false);
    
    

    

   
   
    const exportHandler = async ()=>{
        const listing = await getTickets({ pageNumber: 1, pageSize:totalCount, searchKey: search });
        const fileName = 'collectors'
        const exportType =  exportFromJSON.types.csv

        exportFromJSON({ data : listing.result, fileName, exportType })
    }
    console.log("List : ", List);
    return (
        <>
            <div className="dashboard content-wrapper">
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="new-graph">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dashboard-table mb-3 pb-3">
                                        <div className="table-responsive">
                                            <div className='d-flex flex-row-reverse mb-1'>

                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3">Tickets</h4>
                                                {/* <div class="buttons days-buttons me-1 offset-4">
                                                    <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                                </div> */}
                                              
                                            </div>
                                            <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />
                                            <table id="example2" className="table table-hover">
                                                <tbody>
                                                    {List.map((item, index) => {
                                                        return (
                                                            <tr className="point" key={index.toString()} >
                                                                <td className="border-0" onClick={() => getticketId(item._id)}>
                                                                    <h3></h3>
                                                                    <div className="pl-3">
                                                                        <h3>&nbsp;</h3>
                                                                        <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                        <h3 className='p-2'></h3>
                                                                    </div>

                                                                </td>
                                                                <td className="border-0" onClick={() => getticketId(item._id)}>
                                                                    <div className='d-flex'>
                                                                       
                                                                        <div className="pl-3">
                                                                            <h3 className="mb-0">#{item?.number ? item?.number : 'N/A'}</h3>
                                                                            <h5 className="mb-0">Ticket No.</h5> </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getticketId(item._id)}>
                                                                    <div className='d-flex'>
                                                                       
                                                                        <div className="pl-3">
                                                                            <h3 className="mb-0">{item?.userId?.userName ? item?.userId?.userName : 'N/A'}</h3>
                                                                            <h5 className="mb-0">User Name</h5> </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getticketId(item._id)}>
                                                                    <div className='d-flex'>
                                                                       
                                                                        <div className="pl-3">
                                                                            <h3 className="mb-0">{item?.collectorId?.userName ? item?.collectorId?.userName : 'N/A'}</h3>
                                                                            <h5 className="mb-0">Collector Name</h5> </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getticketId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.title}</h3>
                                                                        <h5 className="mb-0">Ticket Title</h5> </div>
                                                                </td>
                                                               
                                                                <td className="border-0" onClick={() => getticketId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.status}</h3>
                                                                        <h5 className="mb-0">Ticket Status</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div className="pl-3">
                                                                        <h3 className="mb-0">{moment(item?.createdAt).format('YYYY-MM-DD h:m A')}</h3>
                                                                        <h5 className="mb-0">Ticket Date</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => openConfirmDialog(item._id, item.active)}>
                                                                    <div className='text-center'>
                                                                        <h3 className="mb-0 text-center">
                                                                            {item.active ? (
                                                                                <img src="images/eye-open.svg" />) :
                                                                                (<img src="images/eye-close.svg" />)
                                                                            }

                                                                        </h3>
                                                                        <h5 className="mb-0">Change Status</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    {/* <i className="fa fa-angle-right" aria-hidden="true"></i> */}
                                                                   
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div >
                                                {List.length ? (
                                                    <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                                ) : (<></>)}
                                                {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 offset-md-5"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div >

            {/* confirmation dialogue popup model */}
            < Dialog open={isOpen} >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>{dialog}</DialogContent>
                <div className="modal-body">
                <label htmlFor="exampleInputEmail1" className="form-label">Select Status</label>
                    <select className="form-control" onChange={(e)=>{setStatus(e.target.value)}} style={{fontSize : '23px'}}>
                        <option value="PENDING" >PENDING</option>
                        <option value="INPROGRESS" >INPROGRESS</option>
                        <option value="COMPLETED" >COMPLETED</option>
                    </select>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >Close</button>
                    <button type="button" className="btn btn-primary primary-color d-flex align-items-center" onClick={() => updateStatus()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}{"Update Status"}</button>
                </div>
            </Dialog >
            
        </>
    )
}

export default Tickets;