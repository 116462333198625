export const Set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const Get = (key) => {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : false;
}

export const Remove = (key) => {
    localStorage.removeItem(key);
}

export const ClearStorage = (key) => {
    localStorage.clear();
}

