import React from 'react'
import Requests from './Requests';
import Transactions from './Transactions';
import './style.css'
import { useState } from 'react';

  

const TabsWrap = (props) => {
    const [type, setTypes] = React.useState(props.type);
    const [tabs, setTabs] = React.useState(props.tabs);

    const[active, setActive] = useState(0);
  

    return (
        <>
        {
          tabs.length ? 
          <div className='tabs-section'>
            {
                tabs.map( (tab, x) => 
                <div className={ active == x? 'tab active' : 'tab'} onClick={()=>setActive(x)}>
                    {tab}
                </div>
                ) 
            }
          </div>:
          ''
        }

        {
          tabs[active] == "REQUEST" ? 
          <Requests type={type} /> :
          <Transactions type={type} />
        }
        
        </>
            
       
    )
}

export default TabsWrap