/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { nearbyCollectorsList, createGroup, youthGroup, editYouthGroup, deleteYouth, changeYouthGroupStatus } from '../../Services/api';
import { MultiSelect } from "react-multi-select-component";
import { toast } from 'react-toastify';
import { Storage } from '../../Helpers/appResource';
import { mergeCollectors } from '../../Helpers/customMethod';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import Modal from 'react-bootstrap/Modal';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useNavigate } from "react-router-dom";
import Dialog, { DialogTitle, DialogContent } from '@material/react-dialog';
import exportFromJSON from 'export-from-json';
import "./youthGroupManagement.css";

const YouthGroupManagement = () => {
    let timerCount = null;
    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);
    const [youthName, setYouthName] = useState('');
    const [editYouthName, setEditYouthName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [editContactNumber, setEditContactNumber] = useState('');
    const [block, setBlock] = useState('ALL');
    const [editBlock, setEditBlock] = useState('BLOCK ONE');
    const [filterBlock, setFilterBlock] = useState('ALL');
    const [List, setList] = useState([]);
    const [Collectors, SetCollectors] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [spinner, setSpinner] = useState(false);
    const [buttonSpin, setButtonSpin] = useState(false);
    const [show, setShow] = useState(false);
    const [showEdit, setEdit] = useState(false);
    const [editValue, setEditValue] = useState(null);
    const [address, setAddress] = useState("");
    const [editAddress, setEditAddress] = useState("");
    const [coordinates, setCoordinates] = useState([]);
    const [editCoordinates, setEditCoordinates] = useState([]);
    const [recordCheck, setRecordCheck] = useState(1);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteGroupId, setDeleteGroupId] = useState("");
    const [dialog, setDialog] = useState("");
    const [userStatus, setUserStatus] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedYouthGroup, setSelectedYouthGroup] = useState({})
    const [search, setSearch] = useState("");
    const [totalCount, setTotalCount] = useState(0);


    useEffect(() => { youthGroupListing(); nearbyCollectors() }, [pageNumber, search]);

    const youthGroupListing = async () => {
        setSpinner(true);
        setRecordCheck(1)
        const listing = await youthGroup({ block: filterBlock, pageNumber: pageNumber, searchKey: search });
        if (listing.code == 401) {
            navigate("/");
        }
        if (listing.code == 200) {
            setList(listing.result);
        }
        if (listing.count == 0) {
            setRecordCheck(0);
        }
        setSpinner(false);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }
    const nearbyCollectors = async () => {
        const listing = await nearbyCollectorsList({ "longLat": [28.6260606, 77.3668853] });
        // eslint-disable-next-line array-callback-return
        listing.result.map((data) => {
            // eslint-disable-next-line no-unused-expressions
            data.label = data.userName ? data.userName : 'N/A';
            data.value = data._id;
        });
        SetCollectors(listing.result);
    }
    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000)
    }

    const addYouthGroup = async () => {
        if (!block.trim()) return toast.error(Storage.BLOCK_REQUIRED);
        if (!youthName.trim()) return toast.error(Storage.YOUTH_GROUP_NAME_REQUIRED);
        if (!coordinates.length) return toast.error(Storage.LOCATION_REQUIRED);
        // if (!selected.length) return toast.error(Storage.SELECT_COLLECTORS)
        if (selected.length > 15) return toast.error(Storage.CANT_SELECT_MORE)
        let Ids = selected.map(data => data._id);
        setButtonSpin(true);
        const addGroup = await createGroup({
            name: youthName,
            mobile: contactNumber,
            block: block,
            location: {
                longLat: coordinates,
                address: address
            },
            collectors: Ids
        });
        setButtonSpin(false);
        if (addGroup.code == 401) {
            navigate("/");
        }
        if (addGroup.code === 200) {
            setAddress("");
            nearbyCollectors();
            closeNew();
            youthGroupListing();
            toast.success(addGroup.message);
        } else { toast.error(addGroup.message) }
    }

    const handleChange = (event, value) => setPageNumber(value);

    const openNew = () => { setShow(true); setSelected([]); }

    const closeNew = () => setShow(false);

    const openEdit = (value) => {
        value.collectors.map((data) => {
            // eslint-disable-next-line no-unused-expressions
            data.label = data.userName ? data.userName : 'N/A'
            data.value = data._id
        });
        let allCollectors = [...value.collectors, ...Collectors];
        setEditBlock(value.block);
        setEditContactNumber(value.mobile);
        setEditYouthName(value.name);
        setEditAddress(value.location.address)
        setSelected(value.collectors);
        SetCollectors(allCollectors);
        setEditValue(value);
        setEdit(true);
    };

    const updateYouthGroup = async () => {
        if (!selected.length) return toast.error(Storage.SELECT_COLLECTORS);
        if (!selected.length > 10) return toast.error(Storage.CANT_SELECT_MORE);
        setButtonSpin(true);

        let Ids = selected.map(data => data._id);
        // console.log({
        //     youthGroupId: editValue._id,
        //     collectors: Ids,
        //     name: editYouthName,
        //     block: editBlock,
        //     location: {
        //         address: editAddress,
        //         longLat: editCoordinates
        //     },
        //     mobile: editContactNumber
        // })
        const updateGroup = await editYouthGroup({
            youthGroupId: editValue._id,
            collectors: Ids,
            name: editYouthName,
            block: editBlock,
            location: {
                address: editAddress,
                longLat: editCoordinates
            },
            mobile: editContactNumber
        });
        setButtonSpin(false);
        if (updateGroup.code == 401) {
            navigate("/");
        }
        if (updateGroup.code === 200) {
            nearbyCollectors(); setEdit(false); youthGroupListing();
            toast.success(updateGroup.message);
        } else { toast.error(updateGroup.message) }
    }

    const closeEdit = () => { setEdit(false); nearbyCollectors(); }

    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const longLat = await getLatLng(result[0]);
        setAddress(value);
        setCoordinates([longLat.lng, longLat.lat]);
    }
    const handleEditSelect = async value => {
        const result = await geocodeByAddress(value);
        const longLat = await getLatLng(result[0]);
        setEditAddress(value);
        setEditCoordinates([longLat.lng, longLat.lat]);
    }
    useEffect(() => {
        setPageNumber(1)
        youthGroupListing();
    }, [filterBlock]);

    const deleteYouthGroup = async () => {
        setButtonSpin(true);
        const res = await deleteYouth({ id: deleteGroupId })
        if (res.code == 200) {
            toast.success(res.message);
            youthGroupListing();
            setIsDeleteModal(false);
            setButtonSpin(false);
        } else {
            toast.error(res.message);
            setIsDeleteModal(false)
            setButtonSpin(false);

        }
    }

    const updateStatus = async () => {
        setButtonSpin(true);
        const res = await changeYouthGroupStatus({ id: selectedYouthGroup, status: userStatus })
        if (res.code === 200) {
            youthGroupListing();
            setButtonSpin(false);
            toast.success(Storage.STATUS_SUCCESS);
            setIsOpen(false);
        } else {
            toast.error(updateStatus.message);
            setButtonSpin(false);
        }
    }

    const openConfirmDialog = (value, status) => {
        setSelectedYouthGroup(value); setUserStatus(status === true ? false : true); setIsOpen(true);
        setDialog(status === true ? <p>{Storage.DEACTIVATE_COLLECTOR}</p> : <p>{Storage.ACTIVATE_COLLECTOR}</p>);
    };

    const exportHandler = async ()=>{
        const listing = await youthGroup({ block: filterBlock, pageNumber: 1, pageSize:totalCount, searchKey: search });
        const fileName = 'youthGroupManagements'
        const exportType =  exportFromJSON.types.csv
        const expertData = [];
        listing.result?.map((group)=>{
            let collectorName = '';
            group?.collectors?.map((col)=>{
                collectorName += col.userName + ',';
            });
            expertData.push({
                Name : group.name,
                block : group.block,
                mobile : group.mobile,
                location : group.location?.address,
                collectors : collectorName
                
            });
        });
       exportFromJSON({ data : expertData, fileName, exportType })
    }
   
    return <>
        <div className="dashboard content-wrapper">
            <section className="content pb-5 pt-3">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="dashboard-table mb-3 pb-3">
                                <div className="table-responsive">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="pl-3">Youth Group </h4>
                                        <div class="buttons days-buttons me-1 offset-2">
                                                    <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                                </div>
                                        <div className='search-bar d-flex'>
                                            <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box me-3' />
                                            <select value={filterBlock} onChange={(e) => { setFilterBlock(e.target.value); setBlock(e.target.value) }} className="me-3">
                                                <option value="ALL" defaultChecked>ALL</option>
                                                <option value="BLOCK ONE">BLOCK ONE</option>
                                                <option value="BLOCK TWO">BLOCK TWO</option>
                                                <option value="BLOCK THREE">BLOCK THREE</option>
                                                <option value="BLOCK FOUR">BLOCK FOUR</option>
                                                <option value="BLOCK FIVE">BLOCK FIVE</option>
                                                <option value="BLOCK SIX">BLOCK SIX</option>
                                                <option value="BLOCK SEVEN">BLOCK SEVEN</option>
                                                <option value="BLOCK EIGHT">BLOCK EIGHT</option>
                                            </select>
                                            <div className="add-btn"><button className="btn" onClick={() => openNew()}>Add Group</button></div>
                                        </div>
                                    </div>
                                    <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                                    <table id="example2" className="table table-hover">
                                        <tbody>
                                            {List.map((item, index) => {
                                                return (
                                                    <tr className="point" key={index.toString()}>
                                                        <td className={`border-0 
                                                        ${item.block == 'BLOCK ONE'?'block-one':''}
                                                        ${item.block == 'BLOCK TWO'?'block-two':''}
                                                        ${item.block == 'BLOCK THREE'?'block-three':''}
                                                        ${item.block == 'BLOCK FOUR'?'block-four':''}
                                                        ${item.block == 'BLOCK FIVE'?'block-five':''}
                                                        ${item.block == 'BLOCK SIX'?'block-six':''}
                                                        ${item.block == 'BLOCK SEVEN'?'block-seven':''}
                                                        ${item.block == 'BLOCK EIGHT'?'block-eight':''}
                                                        
                                                        `}>
                                                            <h3></h3>
                                                            <div className="pl-3">
                                                                <h3>&nbsp;</h3>
                                                                <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                <h3 className='p-2'></h3>
                                                            </div>

                                                        </td>
                                                        <td className="border-0">
                                                            <div className="pl-3">
                                                                <h3 className="mb-0">{item.name ? item.name : 'N/A'}</h3>
                                                                <h5 className="mb-0">Name</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div className="pl-3">
                                                                <h3 className="mb-0">{item.block ? item.block : 'N/A'}</h3>
                                                                <h5 className="mb-0">Block</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div className="pl-3">
                                                                <h3 className="mb-0">{item.mobile ? item.mobile : 'N/A'}</h3>
                                                                <h5 className="mb-0">Contact Number</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div className="pl-3">
                                                                <h3 className="mb-0">{item.location ? item.location.address : "N/A"}</h3>
                                                                <h5 className="mb-0">Area Location</h5> </div>
                                                        </td>

                                                        <td className="border-0">
                                                            <div className="pl-3">
                                                                <h3 className="mb-0" data-toggle="tooltip" data-placement="top" title="">{mergeCollectors(item.collectors)}</h3>
                                                                <h5 className="mb-0">Collectors</h5> </div>
                                                        </td>
                                                        <td className="border-0" onClick={() => openConfirmDialog(item._id, item.active)}>
                                                            <div className='text-center'>
                                                                <h3 className="mb-0 text-center">
                                                                    {item.active ? (
                                                                        <img src="images/eye-open.svg" />) :
                                                                        (<img src="images/eye-close.svg" />)
                                                                    }

                                                                </h3>
                                                                <h5 className="mb-0">Change Status</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div className='text-center'>
                                                                <img src='images/Delete-button.svg' onClick={() => { setIsDeleteModal(true); setDeleteGroupId(item._id) }}></img>
                                                                <h5 className="mb-0">delete</h5>
                                                            </div>
                                                        </td>
                                                        <td className="border-0" onClick={() => openEdit(item)}>
                                                            <div >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                </svg>
                                                                <h5 className="mb-0">View/Edit</h5> </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        {List.length ? (
                                            <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                        ) : (<></>)}
                                        {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 offset-md-5"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="modal fade" id="addGroupModal" tabIndex="-1" aria-labelledby="addGroupModalLabel" aria-hidden="true">
                <Modal style={{ top: 70 }} show={show} backdrop="static" keyboard={false} className="modal-dialog-scrollable">
                    <div className="modal-content addGroupModal">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addGroupModalLabel">Add Group</h1>
                            <button type="button" className="btn-close" onClick={() => closeNew()} data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Block Name</label>
                                <select value={block} onChange={(e) => setBlock(e.target.value)}>
                                    <option value="BLOCK ONE">BLOCK ONE</option>
                                    <option value="BLOCK TWO">BLOCK TWO</option>
                                    <option value="BLOCK THREE">BLOCK THREE</option>
                                    <option value="BLOCK FOUR">BLOCK FOUR</option>
                                    <option value="BLOCK FIVE">BLOCK FIVE</option>
                                    <option value="BLOCK SIX">BLOCK SIX</option>
                                    <option value="BLOCK SEVEN">BLOCK SEVEN</option>
                                    <option value="BLOCK EIGHT">BLOCK EIGHT</option>
                                </select>
                                {/* <input type="text" className="form-control" id="name" placeholder="Block Name" onChange={(e) => setBlock(e.target.value)} /> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Group Name</label>
                                <input type="text" className="form-control" id="name" placeholder="Name" onChange={(e) => setYouthName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Contact Number</label>
                                <input type="text" className="form-control" id="name" placeholder="Number" onChange={(e) => setContactNumber(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label" >Add Area</label>
                                <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Add address...',
                                                    className: 'location-search-input',
                                                })} className="form-control w-100"
                                            />
                                            <div className={suggestions.length ? "autocomplete-dropdown-container" : ""}>
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div key={suggestion.placeId}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })} className='border-bottom pb-2'
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label" >Add Collectors</label>
                                <MultiSelect hasSelectAll={false} options={Collectors} value={selected} onChange={setSelected}
                                    labelledBy="Select collectors upto 10" />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => closeNew()} data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary primary-color d-flex align-items-center" disabled={buttonSpin} onClick={() => addYouthGroup()}>
                                {(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Add</button>
                        </div>
                    </div>
                </Modal>
            </div>



            {/* edot youth group functionality model */}
            <div id='editGroupModal' className="modal fade" aria-labelledby="addGroupModalLabel" aria-hidden="true">
                <Modal style={{ top: 70 }} show={showEdit} onHide={closeEdit} backdrop="static" keyboard={false}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addGroupModalLabel">Edit Group</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeEdit()}></button>
                        </div>
                        <div className="modal-body editGroupModal">
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Block Name</label>
                                <select defaultValue={editBlock} onChange={(e) => setEditBlock(e.target.value)}>
                                    <option value="BLOCK ONE">BLOCK ONE</option>
                                    <option value="BLOCK TWO">BLOCK TWO</option>
                                    <option value="BLOCK THREE">BLOCK THREE</option>
                                    <option value="BLOCK FOUR">BLOCK FOUR</option>
                                    <option value="BLOCK FIVE">BLOCK FIVE</option>
                                    <option value="BLOCK SIX">BLOCK SIX</option>
                                    <option value="BLOCK SEVEN">BLOCK SEVEN</option>
                                    <option value="BLOCK EIGHT">BLOCK EIGHT</option>
                                </select>
                                {/* <input type="text" className="form-control" id="name" placeholder="Block Name" onChange={(e) => setBlock(e.target.value)} /> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Group Name</label>
                                <input type="text" className="form-control" id="name" placeholder="Name" onChange={(e) => setEditYouthName(e.target.value)} defaultValue={editValue?.name} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Contact Number</label>
                                <input type="text" className="form-control" id="name" placeholder="Number" onChange={(e) => setEditContactNumber(e.target.value)} defaultValue={editValue?.mobile} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label" >Area</label>
                                <PlacesAutocomplete value={editAddress} onChange={setEditAddress} onSelect={handleEditSelect}>
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Add address...',
                                                    className: 'location-search-input',
                                                })} className="form-control w-100"
                                            />
                                            <div className={suggestions.length ? "autocomplete-dropdown-container" : ""}>
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div key={suggestion.placeId}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })} className='border-bottom pb-2'
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                {/* <input type="text" className="form-control" id="area" placeholder="Area" value={editValue?.location?.address} disabled={true} /> */}
                            </div>
                            <MultiSelect
                                hasSelectAll={false}
                                options={Collectors}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select collectors upto 10"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => closeEdit()} data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary d-flex align-items-center" disabled={buttonSpin} onClick={() => updateYouthGroup()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Update</button>
                        </div>
                    </div>
                </Modal>
            </div>
            < Dialog open={isOpen} >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>{dialog}</DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >Close</button>
                    <button type="button" className="btn btn-primary primary-color d-flex align-items-center" onClick={() => updateStatus()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}{userStatus ? "Activate" : "Deactivate"}</button>
                </div>
            </Dialog >
            < Dialog open={isDeleteModal} >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>Are you sure you want to delete youthGroup</DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsDeleteModal(false)} >Close</button>
                    <button type="button" className="btn btn-danger primary-color d-flex align-items-center" onClick={() => deleteYouthGroup()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Delete</button>
                </div>
            </Dialog >
        </div>
    </>;
}

export default YouthGroupManagement;