/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { getTicketDetails, ticketSendMsg, getTicketReceiveMsg } from '../../Services/api';
import { toast } from 'react-toastify';
import "../../style/css/style.css";
import "../../style/css/ticket.css";
import Spinner from 'react-spinner-material';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { io } from "socket.io-client";
import { Urls } from '../../Utils/apiUrl';
import moment from 'moment-timezone';
const TicketDetail = () => {
    let navigate = useNavigate();
    const [details, setDetails] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [messages, setMessages] = useState([]);
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const scrollRef = useRef();
    const socket = useRef();
    const { id } = useParams();
    
    useEffect(() => {
      
       getDetails();
       getTicketMsg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    useEffect(() => {
      if (id) {
        socket.current = io('https://api.dortibox.com/');
        socket.current.emit("add-user", 'admin-'+id);
      }
    }, [id]);

    useEffect(() => {
      if (socket.current) {
        socket.current.on("msg-recieve", (msg) => {

          const msgs = [...messages];
          msgs.push({_id : moment().format('YYYYMMDDhmA'),
          msg: msg,
          sender: 'user'
        });
          setMessages(msgs);
             
            });
      }
    }, [messages]);
    if (!id) return navigate('/tickets', { replace: true });

    async function getDetails() {
        setSpinner(true);
        const details = await getTicketDetails(id);
        if (details.code == 401) {
            navigate("/")
        }
        setSpinner(false);
        // eslint-disable-next-line eqeqeq
        details.code == 200 ? setDetails(details.result) : toast('Collection not found!')

        
    }
    const getTicketMsg = async () =>{
        const msgs = await getTicketReceiveMsg(id);
        setMessages(msgs?.result);
        
    }

    const msgSendHandler = async () =>{
      const sentMsg =  await ticketSendMsg({
        msg: msg,
        ticketId: details?._id,
        userId: details?.userId ? details?.userId : details?.collectorId,
        sender: 'admin'
       });

       
       socket.current.emit("send-msg", {
        to: details?.userId ? details?.userId : details?.collectorId +'-'+details?.number,
        msg : sentMsg?.result,
      });
      setMsg('');
      const msgs = [...messages];
      msgs.push(sentMsg?.result);
      setMessages(msgs);
    }
    
    return (
        <>
            <div className="dashboard content-wrapper">
                <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mb-4">

                                <div className="d-flex justify-content-between">
                                    <div className='d-flex align-items-center'>
                                        <a className="submit me-3 point" onClick={() => navigate('/tickets')} ><i className='fa fa-long-arrow-left'></i></a> <h4>Tickets</h4>
                                        <div title='Edit'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16" >
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                          </div>

                          <div className="messaging">
                            <div className="inbox_msg">

                            <div className="inbox_people">
                            <div className="headind_srch">
                              <div className="recent_heading">
                                <h4>Ticket Detail</h4>
                              </div>
                            
                            </div>
                            <div className="ticket-view"><p>{details?.issue}</p></div>
                            
                          </div>


        <div className="mesgs">
          <div className="msg_history">
            
           
            {messages?.map((msg)=>{
              if(msg.sender == "user"){
                return(
                  <div className="incoming_msg" ref={scrollRef} key={msg?._id}>
                  <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"/> </div>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <p>{msg?.msg}</p>
                      {/* <span className="time_date"> 11:01 AM    |    June 9</span> */}
                      </div>
                  </div>
                </div>
                )
              }else{
                return(
                  <div className="outgoing_msg" ref={scrollRef} key={msg?._id}>
                    <div className="sent_msg">
                      <p>{msg?.msg}</p>
                      {/* <span className="time_date"> 11:01 AM    |    June 9</span>  */}
                      </div>
                  </div>
                )
              }

            })}

          </div>
          <div className="type_msg">
            <div className="input_msg_write">
              <input type="text" className="write_msg" value={msg} onChange={(e)=>{setMsg(e.target.value)}}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  msgSendHandler()
                }
            }} placeholder="Type a message" />
              <button className="msg_send_btn" onClick={msgSendHandler} type="button"><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      
        
                      </div>
                    </div>


                    
              </div>
          </section>
      </div>
            
        </>
    )
}

export default TicketDetail;