import React from "react";

const footer = () => {
  return (
    <>
      <footer className="pt-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="copyright">
            <p className="mt-3 mb-0">
              &copy; The Freetown Waste Transformers 2022. All rights reserved.
            </p>
          </div>
          <div className="designed-by">
            {/* <p className="mb-0 mt-3">Developed and Designed by MeDomot, Inc.</p> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
