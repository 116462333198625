import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ClearStorage } from "../../Helpers/localStorage";
import { Set } from "../../Helpers/localStorage";
import { Storage } from "../../Helpers/appResource";

export default function Layout(props) {
  const dataFetchedRef = useRef(false);
  let navigate = useNavigate();
  let navLink = window.location.pathname;
  const [state, stateName] = useState(0);
  const [activeClass, setActiveClass] = useState("active");

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    console.log(navLink, "Nav Sidebar Called");
    changeRoutes(navLink);
  }, []);

  useEffect(() => {
    detailsRoutes();
  }, [navLink]);

  function changeRoutes() {
    Set(Storage.PAGE_NUMBER, 1);
    Set(Storage.SEARCH, "");
    detailsRoutes();
  }

  const detailsRoutes = () => {
    let splitLink = navLink.split("/");
    switch (splitLink[1]) {
      case "userDetails":
        stateName("/userList");
        setActiveClass("active")
        break;
      case "collectorDetails":
        stateName("/collector");
        setActiveClass("active")
        break;
      case "collection-details":
        stateName("/collection-request");
        setActiveClass("active")
        break;
      default:
        setActiveClass("")
        break;
    }
  };

  const logOut = () => {
    ClearStorage();
    navigate(`/`, { replace: true });
  };

  return (
    <>
      <nav
        id="sidebar"
        className={`${props.toggleSideBar ? "close-sidebar" : ""}`}
      >
        <div className="p-4">
          {/* <img className="img logo rounded-circle mb-5" src={require('../../image/logo512.png')} ></img> */}
          <ul className="list-unstyled components mb-5 point">
            <li>
              <NavLink
                className="myClass"
                onClick={() => changeRoutes()}
                to="/dashboard"
              >
                {" "}
                <img
                  src="images/dashboard.svg"
                  alt=""
                  className="img-fluid me-3"
                />{" "}
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                className={"myClass " + (state == "/userList" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/userList"
              >
                <img src="images/user.svg" alt="" className="img-fluid me-3" />
                Users Management
              </NavLink>
            </li>
            <li>
              <NavLink
                className={"myClass " + (state == "/collector" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/collector"
              >
                <img
                  src="images/collector-management.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Collector Management
              </NavLink>
            </li>
            <li>
              <NavLink
                className={"myClass " + (state == "/youthGroupManagement" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/youthGroupManagement"
              >
                <img
                  src="images/volunteer-management.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Youth Group Management
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={
                  "myClass " +
                  (state === "/priceManagement" ? "selectedMenu" : "")
                }
                onClick={() => changeRoutes("/priceManagement", true)}
              >
                <img src="images/user.svg" alt="" className="img-fluid me-3" />
                Price Management
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                className={
                  "myClass " + (state === "/area" ? "selectedMenu" : "")
                }
                onClick={() => changeRoutes("/area", true)}
              >
                <img src="images/user.svg" alt="" className="img-fluid me-3" />
                Area Management
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                className={
                  "myClass " + (state === "/segregated-waste" ? "selectedMenu" : "")
                }
                onClick={() => changeRoutes("/segregated-waste", true)}
              >
                <img src="images/user.svg" alt="" className="img-fluid me-3" />
                Segregated Waste
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className={"myClass " + (state == "/collection-request" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/collection-request"
              >
                <img
                  src="images/collection-request.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Collection Request
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={
                  "myClass " + (state === "/report" ? "selectedMenu" : "")
                }
                onClick={() => changeRoutes("/report", true)}
              >
                <img src="images/user.svg" alt="" className="img-fluid me-3" />
                Report
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className={"myClass " + (state == "/transaction" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/transaction"
              >
                <img
                  src="images/transaction.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                className={"myClass " + (state == "/usersFeedback" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/usersFeedback"
              >
                <img
                  src="images/collection-request.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Users Feedbacks
              </NavLink>
            </li>
            <li>
              <NavLink
                className={"myClass " + (state == "/wasteVolume" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/wasteVolume"
              >
                <img
                  src="images/collection-request.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Waste Volume
              </NavLink>
            </li>
            <li>
              <NavLink
                className={"myClass " + (state == "/tickets" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/tickets"
              >
                <img
                  src="images/collection-request.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Tickets
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={
                  "myClass " +
                  (state === "/transaction" ? "selectedMenu" : "")
                }
                onClick={() => changeRoutes("/transaction", true)}
              >
                Price Management
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className={"myClass " + (state == "/settings" ? activeClass : "")}
                onClick={() => changeRoutes()}
                to="/settings"
              >
                <img
                  src="images/settings.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                General Settings
              </NavLink>
            </li>

            <li>
              <a
                className="myClass"
                onClick={() => logOut()}
              >
                <img
                  src="images/logout.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Log Out
              </a>
            </li>

            {/* <li>
              <img src="images/user.svg" alt="" className="img-fluid me-3" />
              <a onClick={() => logOut()}>Log out</NavLink>
            </li> */}
            {/* <li>
                            <a id="Settings" onClick={() => changeRoutes("/settings")}>Settings</NavLink>
                        </li> */}
            {/* <li>
                  <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Pages</NavLink>
                  <ul className="collapse list-unstyled" id="pageSubmenu">
                    <li>
                      <a >Page 1</NavLink>
                    </li>
                    <li>
                      <a >Page 2</NavLink>
                    </li>
                    <li>
                      <a >Page 3</NavLink>
                    </li>
                  </ul>
                </li> */}
          </ul>
          {/* <div className="footer">
                        Copyright &copy; 2022 All rights reserved<i className="icon-heart" aria-hidden="true"></i>
                    </div> */}
        </div>
      </nav>
    </>
  );
}
