import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  cutout: 60,
  layout: {
    padding: 10,
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",

      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

const data = {
  labels: ["Spur Road", "Brookfields", "Congo Town"],
  datasets: [
    {
      label: "# of Votes",
      data: [61, 35, 83],
      backgroundColor: [
        "rgb(0, 181 , 214)",
        "rgb(232, 206, 70)",
        "rgba(242, 97, 34)",
      ],
      borderColor: [
        "rgb(0, 181 , 214)",
        "rgb(232, 206, 70)",
        "rgba(242, 97, 34)",
      ],
      borderWidth: 0,
      hoverOffset: 20,
    },
  ],
};
const DoughnutData = () => {
  return (
    <div className="doughnut-chart">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutData;
