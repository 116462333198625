/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import AccountInfo from "./accountInfo";
import ChangePassword from "./changePassword";

const Settings = () => {
  const [state, setState] = useState(1);

  useEffect(() => {}, []);

  const Navigation = (key) => {
    switch (key) {
      case 1:
        setState(key);
        break;
      case 2:
        setState(key);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="dashboard content-wrapper">
        <h4 className="card-header">Account Details</h4>
        <div className="card-body">
          <nav className="nav nav-borders">
            <a
              className={"nav-link point ms-0 " + (state === 1 ? "active" : "ms-0")}
              onClick={() => Navigation(1)}
            >
              Account Info
            </a>
            <a
              className={"nav-link point " + (state === 2 ? "active" : "ms-0")}
              onClick={() => Navigation(2)}
            >
              Change Password
            </a>
          </nav>
        </div>
        {state === 1 ? <AccountInfo /> : <ChangePassword />}
      </div>
      
    </>
  );
};

export default Settings;
