/* eslint-disable react-hooks/exhaustive-deps */
('use Strict');
import React, { useEffect, useState } from 'react';
import { feedBackList } from '../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../Helpers/appResource';
import { Set, Get } from '../../Helpers/localStorage';
import moment from 'moment-timezone';
import { useNavigate } from "react-router-dom";
import Dialog, { DialogTitle, DialogContent } from "@material/react-dialog";
import exportFromJSON from 'export-from-json';

const dateFormat = "YYYY-MM-DD";
const UserFeedbacks = () => {

    let timerCount = null;
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : 1);
    const [search, setSearch] = useState(Get(Storage.SEARCH) ? Get(Storage.SEARCH) : "");
    const [spinner, setSpinner] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState({})
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        feedbackListing()
    }, [pageNumber, search]);



    const feedbackListing = async () => {
        setSpinner(true);
        setRecordCheck(1);
        const listing = await feedBackList({ pageNumber: pageNumber, searchKey: search });
        if (listing.code == 401) {
            navigate('/')
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }

    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search) };


    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000);
    }
    const openModal = (data) => {
        setSpinner(true);
        setSelectedReview(data);
        setIsOpen(true)
        setSpinner(false);
    }

    const exportHandler = async ()=>{
        
        const listing = await feedBackList({ pageNumber: 1, pageSize:totalCount, searchKey: search });
        const fileName = 'feedBacks'
        const exportType =  exportFromJSON.types.csv
        const expertData = [];
        listing.result?.map((feed)=>{
            expertData.push({
              
                UserName : feed?.userId?.userName,
                Email : feed?.email,
                Title : feed?.title,
                Message : feed?.message,
                Date : feed?.createdAt,
               
              
                
            });
        });
        exportFromJSON({ data : expertData, fileName, exportType })
    }
    return (
        <>
            <div className="dashboard">
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="new-graph">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dashboard-table mb-3 pb-3">
                                        <div className="table-responsive px-2">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3">Users Feedbacks</h4>
                                                <div class="buttons days-buttons me-1 offset-4">
                                                    <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                                </div>
                                                <div className="search-bar d-flex align-items-center">
                                                    <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box me-3' />                                                </div>
                                            </div>
                                            <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                                            <table id="example2" className="table table-hover">
                                                <tbody>
                                                    {List.map((item, index) => {
                                                        return (
                                                            <tr className="point" key={index.toString()} onClick={() => openModal(item)}>
                                                                <td className="border-0">
                                                                    <h3></h3>
                                                                    <div className='text-center'>
                                                                        <h3>&nbsp;</h3>
                                                                        <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                        <h3 className='p-2'></h3>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.userId.userName || "N/A"}</h3>
                                                                        <h5 className="mb-0">UserName</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.email || "N/A"}</h3>
                                                                        <h5 className="mb-0">Email</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div className="pl-3">
                                                                        <h3 className="mb-0">{item.title || "N/A"} </h3>
                                                                        <h5 className="mb-0">Title</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div className="pl-3">
                                                                        <h3 className="mb-0">{item.message || "N/A"}</h3>
                                                                        <h5 className="mb-0">Message</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div className="pl-3">
                                                                        <h3 className="mb-0">{moment(item.createdAt).format('YYYY-MM-DD')}</h3>
                                                                        <h5 className="mb-0">Date</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0"><i className="fa fa-angle-right" aria-hidden="true"></i> </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div>
                                                {List.length ? (
                                                    <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                                ) : (<></>)}
                                                {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Dialog open={isOpen}>
                <DialogTitle>{selectedReview?.userId?.userName || "N/A"}</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className="col-md-6">
                            Title :
                        </div>
                        <div className="col-md-6">
                            {selectedReview.title || "N/A"}
                        </div>
                        <div className="col-md-6">
                            Message :
                        </div>
                        <div className="col-md-6">
                            {selectedReview?.message || "N/A"}
                        </div>
                    </div>
                </DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >
                        Close
                    </button>
                </div>
            </Dialog>
        </>

    )
}

export default UserFeedbacks;