/* eslint-disable react-hooks/exhaustive-deps */
('use Strict');
import React, { useEffect, useState } from 'react';
import { collectionRequest } from '../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../Helpers/appResource';
import { Set, Get } from '../../Helpers/localStorage';
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import exportFromJSON from 'export-from-json';

const CollectionRequest = () => {
    const navigate = useNavigate();
    let timerCount = null;
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [filter, setFilter] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    useEffect(()=>{
        const queryParameters = new URLSearchParams(window.location.search)
        if(queryParameters.size){
            const status = queryParameters.get("status")
            const start = queryParameters.get("start")
            const end = queryParameters.get("end")
            console.log(status, start, end);
            setFilter({startDate: start, endDate: end, status: status});
        }
    },[window.location.search])

    useEffect(() => {
        setPageNumber(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : pageNumber);
        setSearch(Get(Storage.SEARCH));
        console.log("filter : ", filter);
        collectionListing();
    }, [pageNumber, search, filter, window.location.search]);



    const collectionListing = async () => {
        const queryParameters = new URLSearchParams(window.location.search);
        let filterss = {};
        if(queryParameters.size){
            const status = queryParameters.get("status")
            const start = queryParameters.get("start")
            const end = queryParameters.get("end")
            const duration = queryParameters.get("duration")
            const period = queryParameters.get("period")
            const name = queryParameters.get("name")
            filterss = {startDate: start, endDate: end, status: status, duration : duration, period : period, name: name};
        }
        setSpinner(true);
        setRecordCheck(1);
        const listing = await collectionRequest({ pageNumber: pageNumber, searchKey: search, filterIds: filterss});
        if (listing.code == 401) {
            navigate("/");
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }

    const detailPage = (id) => { Set(Storage.PAGE_NUMBER, pageNumber); Set(Storage.SEARCH, search); navigate(`/collection-details/:${id}`) };
    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search) };


    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000);
    }
    
    const exportHandler = async ()=>{
        const queryParameters = new URLSearchParams(window.location.search);
        let filterss = {};
        if(queryParameters.size){
            const status = queryParameters.get("status")
            const start = queryParameters.get("start")
            const end = queryParameters.get("end")
            const duration = queryParameters.get("duration")
            const period = queryParameters.get("period")
            const name = queryParameters.get("name")
            filterss = {startDate: start, endDate: end, status: status, duration : duration, period : period, name: name};
        }
        const listing = await collectionRequest({ pageNumber: 1, pageSize:totalCount,  searchKey: search, filterIds: filterss });
        const fileName = 'collection-request'
        const exportType =  exportFromJSON.types.csv
        const expertData = [];
        listing.result?.map((col)=>{
           
            expertData.push({
                ReferenceId : col.referenceId,
                UserName : col?.userId?.userName,
                collector : col?.collectorId?.userName,
                youthGroupName : col?.youthGroupId?.name,
                RequestType : col?.isRecurring ? 'Schedule' : 'Instant',
                wasteVolume : col?.wasteQuantity,
                status : col?.status,
                paymentStatus : col?.paymentStatus,
                
            });
        });
        exportFromJSON({ data : expertData, fileName, exportType })
    }
    return (
        <>
            <div className="dashboard">
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="new-graph">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dashboard-table mb-3 pb-3">
                                        <div className="table-responsive px-2">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3">Collections</h4>
                                                <div class="buttons days-buttons me-1 offset-4">
                                                    <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                                </div>
                                                <div className="search-bar">
                                                    <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box' />
                                                </div>
                                            </div>
                                            <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                                            <table id="example2" className="table table-hover">
                                                <tbody>
                                                    {List.map((item, index) => {
                                                        return (
                                                            <tr className="point" key={index.toString()} onClick={() => detailPage(item._id)}>
                                                                {/*<tr className="point" key={index.toString()} >*/}
                                                                <td className="border-0">
                                                                    <h3></h3>
                                                                    <div className='text-center'>
                                                                        <h3>&nbsp;</h3>
                                                                        <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                        <h3 className='p-2'></h3>
                                                                    </div>

                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.referenceId}</h3>
                                                                        <h5 className="mb-0">Reference Id</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    {/* <div className=""><img src="dist/images/gwen.png" alt=""></img></div> */}
                                                                    <div className="pl-3">
                                                                        <h3 className="mb-0">{item?.userId ? item?.userId?.userName : 'N/A'}</h3>
                                                                        <h5 className="mb-0">User Name</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.collectorId ? item?.collectorId?.userName : 'N/A'}</h3>
                                                                        <h5 className="mb-0">Collector Name</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.wasteQuantity}{'kg/lbs'}</h3>
                                                                        <h5 className="mb-0">Waste Quantity</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.status}</h3>
                                                                        <h5 className="mb-0">Job Status</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.paymentStatus}</h3>
                                                                        <h5 className="mb-0">Payment Status</h5> </div>
                                                                </td>
                                                                <td className="border-0"><i className="fa fa-angle-right" aria-hidden="true"></i> </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div>
                                                {List.length ? (
                                                    <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                                ) : (<></>)}
                                                {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default CollectionRequest;