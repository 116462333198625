('use Strict');
import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../Helpers/appResource';
import { Set, Get } from '../../Helpers/localStorage';
import moment from 'moment-timezone';
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import Dialog, { DialogTitle, DialogContent } from "@material/react-dialog";
import { changeWastePriceStatus, createWastePrice, editWastePrice, getWastePrice, removeWasteVolume} from '../../Services/api';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import exportFromJSON from 'export-from-json';

const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;
const WasteVolume = () => {
    let timerCount = null;
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [locationType, setLocationType] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [spinner, setSpinner] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [dialog, setDialog] = useState("");
    const [buttonSpin, setButtonSpin] = useState(false);
    const [show, setShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isAddModel, setIsAddModel] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        setPageNumber(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : pageNumber);
        wasteVolumesList();
    }, [pageNumber, locationType]);

    
    const wasteVolumesList = async () => {
        setSpinner(true);
        setRecordCheck(1);
        const listing = await getWastePrice({ pageNumber: pageNumber, locationType: locationType });
        if (listing.code == 401) {
            navigate('/')
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }

    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); };

    const openEdit = (item) => {
        setEditData(item);
        setEditModal(true);
    }

    // update item

    const handleEdit = () => {
        console.log(editData);
        if(!editData.price.toString().length) return toast.error(Storage.PRICE_REQUIRED);
        if(!editData.minVolume.length && !editData.maxVolume.length) return toast.error(Storage.MIN_MAX_VOLUME_REQUIRED);

        editWastePrice(editData).then( (resData)=>{
            if(resData.code == 204)  return toast.error(resData.message);
            toast.success(Storage.WASTE_VOLUME_UPDATED);
            setEditModal(false);
            setEditData('');
            wasteVolumesList();
        }).catch( (error) => {
            toast.error(error.message);
        });
        
    }

    // delete item

    const deleteById = () => {
        if(!editData._id) return toast.error(Storage.ID_REQUIRED);

        removeWasteVolume(editData).then((resData)=>{
            toast.success(Storage.WASTE_VOLUME_REMOVED);
            setIsDeleteModal(false)
            setEditData('');
            wasteVolumesList();
        }).catch( (error) => {
            toast.error(error.message);
        });
        
    }

    
    const openConfirmDialog = (item) => {
        setEditData(item); setIsOpen(true);
        setDialog(item.active === true ? <p>{Storage.DEACTIVATE_VOLUME}</p> : <p>{Storage.ACTIVATE_VOLUME}</p>);
    };

    // update item active status

    const updateStatus = async () => {
        if(!editData._id) return toast.error(Storage.ID_REQUIRED);
        
        const updateData = {
            _id: editData._id,
            active: editData.active? false : true
        }
        
        changeWastePriceStatus(updateData).then( (resData)=>{
            if(resData.code == 204)  return toast.error(resData.message);
            toast.success(Storage.WASTE_VOLUME_UPDATED);
            setIsOpen(false)
            setEditData('');
            wasteVolumesList();
        }).catch( (error) => {
            toast.error(error.message);
        });
    }

    // Add waste Volume

    
    const openAdd = (item) => {
        setEditData({locationType: 'HOUSEHOLD', minVolume: '', maxVolume: '', price: '', unit:'kg'});
        setIsAddModel(true);
    }

    const handleAdd = () => {
        if(!editData.price.length) return toast.error(Storage.PRICE_REQUIRED);
        if(!editData.minVolume.length && !editData.maxVolume.length) return toast.error(Storage.MIN_MAX_VOLUME_REQUIRED);

        createWastePrice(editData).then( (resData) => {
            if(resData.code == 204)  return toast.error(resData.message);
            toast.success(Storage.WASTE_VOLUME_ADDED)
            setIsAddModel(false)
            setEditData('');
            wasteVolumesList()
        }).catch( (error) => {
            toast.error(error.message);
        });
        
        
    }
    const exportHandler = async ()=>{
        
        const listing = await getWastePrice({ pageNumber: 1, pageSize:totalCount, locationType: locationType });
        const fileName = 'wasteVolume'
        const exportType =  exportFromJSON.types.csv
        const expertData = [];
        listing.result?.map((waste)=>{
            expertData.push({
                LocationType : waste?.locationType,
                MinVolume : waste?.minVolume,
                MaxVolume : waste?.maxVolume,
                Price : waste?.price,
                Unit : waste?.unit,
                Status : waste?.active,
            });
        });
        exportFromJSON({ data : expertData, fileName, exportType })
    }
  return (
    <>
    <div className="dashboard">
        <section className="content pb-5 pt-3">
            <div className="container-fluid">
                <div className="new-graph">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dashboard-table mb-3 pb-3">
                                <div className="table-responsive px-2">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="pl-3">Waste Volume </h4>
                                        <div class="buttons days-buttons me-1 offset-4">
                                            <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                        </div>
                                        <div className="search-bar d-flex align-items-center">
                                            <select onChange={(e)=> setLocationType(e.currentTarget.value)} className='me-3'>
                                                <option value='' >ALL</option>
                                                <option value="HOUSEHOLD" >HOUSEHOLD</option>
                                                <option value="BUSINESS" >BUSINESS</option>
                                            </select>
                                            <div className="add-btn"><button className="btn" onClick={() => openAdd()}>Add Waste Volume</button></div>
                                        </div>
                                    </div>
                                    <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />
                                
                                    <table id="example2" className="table table-hover">
                                        <tbody>
                                            {List.map((item, index) => {
                                                return (
                                                    <tr className="point" key={index.toString()}>
                                                        <td className="border-0">
                                                            <h3></h3>
                                                            <div className='text-center'>
                                                                <h3>&nbsp;</h3>
                                                                <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                <h3 className='p-2'></h3>
                                                            </div>

                                                        </td>
                                                        <td className="border-0">
                                                                <div>
                                                                    <h3 className="mb-0">{item.locationType ? item.locationType : 'N/A'}</h3>
                                                                    <h5 className="mb-0">Location Type</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div>
                                                                <h3 className="mb-0">{item.minVolume}</h3>
                                                                <h5 className="mb-0">Minimum Volume</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div>
                                                                <h3 className="mb-0">{item.maxVolume ? item.maxVolume : 'N/A'}</h3>
                                                                <h5 className="mb-0">Maximum Volume</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div>
                                                                <h3 className="mb-0">{item.price ? item.price : 'N/A'}</h3>
                                                                <h5 className="mb-0">Price</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div>
                                                                <h3 className="mb-0">{item.unit ? item.unit : 'N/A'}</h3>
                                                                <h5 className="mb-0">Unit</h5> </div>
                                                        </td>
                                                        <td className="border-0">
                                                            <div>
                                                                <h3 className="mb-0">{item.active ? "Activated" : "Deactivated"}</h3>
                                                                <h5 className="mb-0">Status</h5> </div>
                                                        </td>
                                                        <td className="border-0" onClick={() => openEdit(item)}>
                                                            <div >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                </svg>
                                                                <h5 className="mb-0">Edit</h5> </div>
                                                        </td>
                                                        <td className="border-0" onClick={() => { setEditData(item); setIsDeleteModal(true);}}>
                                                            <div className='text-center'>
                                                                <img src='images/Delete-button.svg' ></img>
                                                                <h5 className="mb-0">delete</h5> </div>
                                                        </td>
                                                        <td className="border-0" onClick={() => openConfirmDialog(item)}>
                                                            <div className='text-center'>
                                                                <h3 className="mb-0 text-center">
                                                                    {item.active ? (
                                                                        <img src="images/eye-open.svg" />) :
                                                                        (<img src="images/eye-close.svg" />)
                                                                    }

                                                                </h3>
                                                                <h5 className="mb-0">Change Status</h5> </div>
                                                        </td>
                                                        
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        {List.length ? (
                                            <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                        ) : (<></>)}
                                        {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/** Add Model */}

        <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
            <Modal style={{ top: 70 }} show={isAddModel} backdrop="static" keyboard={false} className="modal-dialog-scrollable">
                <div className="modal-content addGroupModal">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="addGroupModalLabel">Add Waste Volume</h1>
                        <button type="button" className="btn-close" onClick={() => setIsAddModel(false)} data-bs-dismiss="modal" aria-label="Close" ></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Location Type</label>
                            <select onChange={(e) => setEditData({...editData, locationType: e.currentTarget.value})}>
                                <option value="HOUSEHOLD" >HOUSEHOLD</option>
                                <option value="BUSINESS" >BUSINESS</option>
                            </select>
                            {/* <input type="text" className="form-control" id="name" placeholder="Block Name" onChange={(e) => setBlock(e.target.value)} /> */}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Minimum Volume</label>
                            <input defaultValue={editData?.minVolume} type="text" className="form-control" id="min" placeholder="Minimum Volume" onChange={(e)=> setEditData({...editData, minVolume: e.currentTarget.value})} />
                        </div>
                        <div className="mb-3">
                            <label h className="form-label">Maximum Volume</label>
                            <input defaultValue={editData?.maxVolume} type="text" className="form-control" id="max" placeholder="Maximum Volume" onChange={(e)=> setEditData({...editData, maxVolume: e.currentTarget.value})} />
                        </div>
                        <div className="mb-3">
                            <label h className="form-label">Price</label>
                            <input defaultValue={editData?.price} type="text" className="form-control" id="price" placeholder="Price" onChange={(e)=> setEditData({...editData, price: e.currentTarget.value})} />
                        </div>
                        <div className="mb-3">
                            <label h className="form-label">Unit</label>
                            <select onChange={(e)=> setEditData({...editData, unit: e.currentTarget.value})}>
                                <option value="kg">kg</option>
                                <option value="ltr">ltr</option>
                            </select>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setIsAddModel()} data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary primary-color d-flex align-items-center" disabled={buttonSpin} onClick={() => handleAdd()}>
                            {(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Add</button>
                    </div>
                </div>
            </Modal>
            </div>

        {/** Edit model */}

        <div className="modal fade" id="editModel" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <Modal className='editCollector' style={{ top: 80 }} show={editModal} backdrop="static" keyboard={false}>
                <div className="modal-content addGroupModal">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editCollectorModalLabel">Edit Volume</h1>
                        <button type="button" className="btn-close" onClick={() => setEditModal(false)} data-bs-dismiss="modal" aria-label="Close" ></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Location Type</label>
                            <select onChange={(e)=> setEditData({...editData, locationType: e.currentTarget.value})}>
                                <option value="HOUSEHOLD" selected={editData.locationType == "HOUSEHOLD" ? true : false}>HOUSEHOLD</option>
                                <option value="BUSINESS" selected={editData.locationType == "BUSINESS" ? true : false}>BUSINESS</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Minimum Volume</label>
                            <input defaultValue={editData.minVolume} type="text" className="form-control" id="min" placeholder="Minimum Volume" onChange={(e)=> setEditData({...editData, minVolume: e.currentTarget.value})} />
                        </div>
                        <div className="mb-3">
                            <label h className="form-label">Maximum Volume</label>
                            <input defaultValue={editData.maxVolume} type="text" className="form-control" id="max" placeholder="Maximum Volume" onChange={(e)=> setEditData({...editData, maxVolume: e.currentTarget.value})} />
                        </div>
                        <div className="mb-3">
                            <label h className="form-label">Price</label>
                            <input defaultValue={editData.price} type="text" className="form-control" id="price" placeholder="Price" onChange={(e)=> setEditData({...editData, price: e.currentTarget.value})} />
                        </div>
                        <div className="mb-3">
                            <label h className="form-label">Unit</label>
                            <select onChange={(e)=> setEditData({...editData, unit: e.currentTarget.value})}>
                                <option value="kg" selected={editData.unit == "kg" ? true : false}>kg</option>
                                <option value="ltr" selected={editData.unit == "ltr" ? true : false}>ltr</option>
                            </select>
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Password</label>
                            <input type="password" className="form-control" id="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Confirm Password</label>
                            <input type="password" className="form-control" id="password" placeholder="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div> */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setEditModal(false)} data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary primary-color d-flex align-items-center" disabled={buttonSpin} onClick={() => handleEdit()}>
                            {(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Update</button>
                    </div>
                </div>
            </Modal>
        </div>
    </div>
    
    {/* confirmation dialogue popup model */}
    < Dialog open={isOpen} onClose={() => setIsOpen(false)} >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>{dialog}</DialogContent>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >Close</button>
            <button type="button" className="btn btn-primary primary-color d-flex align-items-center" onClick={() => updateStatus()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}{!editData.active ? "Activate" : "Deactivate"}</button>
        </div>
    </Dialog >
    < Dialog open={isDeleteModal} onClose={() => setIsDeleteModal(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to delete this waste volume</DialogContent>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setIsDeleteModal(false)} >Close</button>
            <button type="button" className="btn btn-danger primary-color d-flex align-items-center" onClick={() => deleteById()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Delete</button>
        </div>
    </Dialog >
    </>
  )
}

export default WasteVolume