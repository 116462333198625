import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { deleteAccount, deleteAccountOtp } from "../../Services/api";
import { useNavigate } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';


const DeleteAccount = () => {

    const [spinner, setSpinner] = useState(false);
    const [countryName, setCountryName] = useState("");
    const [mobile, setMobile] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [change, setChange] = useState("USER");
    const [otp, setOtp] = useState(0);
    const [section, setSection] = useState("DELETE");

    let navigate = useNavigate();

    useEffect(() => {

    }, []);

    const sendOTP = async () => {
        const result = await deleteAccountOtp({ userType: change, countryCode: countryCode, mobileNumber: mobile });
        if (result.code == 200) {
            toast.success(result.message);
            setSection("OTP");
        } else {
            toast.error(result.message)
        }
    }

    const deleteAccountApiCall = async () => {
        const result = await deleteAccount({ userType: change, countryCode: countryCode, mobileNumber: mobile, OTP: otp });
        if (result.code == 200) {
            toast.success(result.message);
            setSection("OTP");
        } else {
            toast.error(result.message)
        }
    }

    const setMobileNumber = (e, number, country) => {
        console.log(country, number)
        setMobile(number);
        setCountryName(country.iso2.toUpperCase());
        setCountryCode(`+${country.dialCode}`)
    }


    return (
        <section className="login w-100">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-5">
                        {section == "DELETE" ? (
                            <div className="d-flex justify-content-center align-items-center mb-5 tabs-button">
                                <button type="button"
                                    className={`w-100 border-0 ${change == "USER" ? "primary-color text-white" : "text-dark"} d-flex align-items-center justify-content-center`} onClick={() => setChange("USER")}>User</button>
                                <button type="button"
                                    className={`w-100 border-0 ${change == "COLLECTOR" ? "primary-color text-white" : "text-dark"}  d-flex align-items-center justify-content-center`} onClick={() => setChange("COLLECTOR")}>Collector</button>
                            </div>
                        ) : (
                            <>
                            </>
                        )}

                        {section == "DELETE" ? (
                            <div className="login-container text-center p-4">
                                <h4 className="mb-4">Delete Account</h4>
                                <div>
                                    <div className="input-box mb-3">
                                        <IntlTelInput preferredCountries={['SL']} containerClassName="intl-tel-input" separateDialCode="true" inputClassName="form-control" onPhoneNumberChange={(e, mobile, country) => setMobileNumber(e, mobile, country)} />
                                    </div>
                                    <div className="sign-in-btn">
                                        <button
                                            type="button"
                                            disabled={spinner}
                                            className="w-100 border-0 primary-color text-white d-flex align-items-center justify-content-center"
                                            onClick={() => sendOTP()}
                                        >
                                            {spinner ? (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                ""
                                            )}
                                            Send Otp
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className="login-container text-center p-4">
                            <h4 className="mb-4">Enter Otp</h4>
                            <div>
                                <div className="input-box mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={countryCode + mobile}
                                        placeholder="Enter Mobile Number"
                                        disabled
                                    ></input>
                                </div>
                                <div className="input-box mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => setOtp(e.target.value)}
                                        placeholder="Enter OTP"
                                    ></input>
                                </div>
                                <div className="sign-in-btn">
                                    <button
                                        type="button"
                                        disabled={spinner}
                                        className="w-100 border-0 primary-color text-white d-flex align-items-center justify-content-center"
                                        onClick={() => deleteAccountApiCall()}
                                    >
                                        {spinner ? (
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            ""
                                        )}
                                        Delete Account
                                    </button>
                                </div>
                            </div>
                        </div>)}



                    </div>
                </div>
            </div>
        </section>
    );
};

export default DeleteAccount;
