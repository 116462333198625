/* eslint-disable react-hooks/exhaustive-deps */
('use Strict');
import React, { useEffect, useState } from 'react';
import { transactions, transactionDetails } from '../../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../../Helpers/appResource';
import { Set, Get } from '../../../Helpers/localStorage';
import moment from 'moment-timezone';
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import Dialog, { DialogTitle, DialogContent } from "@material/react-dialog";


const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;
const Transactions = (props) => {

    let timerCount = null;
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [startDate, setStartDate] = useState(moment(new Date(), dateFormat).subtract(7, 'days'));
    const [endDate, setEndDate] = useState(moment(new Date(), dateFormat));
    const [paymentStatus, setPaymentStatus] = useState("ALL");
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [filterIds, setFilterIds] = useState({});
    
    useEffect(()=>{
        Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, '')
    },[])

    useEffect(()=>{
        const projectId = window.location.pathname.split(':');

        props.type == "USER"? setFilterIds({userId:projectId[1]}) : setFilterIds({collectorId: projectId[1]});
    },[props.type])

    useEffect(() => {
        setPageNumber(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : pageNumber);
        setSearch(Get(Storage.SEARCH));
        collectionListing();
    }, [pageNumber, search, filterIds]);


    const collectionListing = async () => {
        if(!Object.keys(filterIds).length) return ;
        setSpinner(true);
        setRecordCheck(1);
        console.log("FILTER IDS", filterIds);
        const listing = await transactions({ startDate: startDate, endDate: endDate, pageNumber: pageNumber, status: paymentStatus, filterIds: filterIds  });
        if (listing.code == 401) {
            navigate('/')
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setCount(Math.ceil(listing.count / 10));
    }

    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search) };


    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000);
    }
    const changeDate = async (data) => {
        if(!Object.keys(filterIds).length) return ;
        setStartDate(data[0]);
        setEndDate(data[1]);
        setSpinner(true);
        setRecordCheck(1);
        const listing = await transactions({ startDate: data[0], endDate: data[1], pageNumber: pageNumber, status: paymentStatus, filterIds: filterIds });
        if (listing.code == 401) {
            navigate('/')
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setCount(Math.ceil(listing.count / 10));
    }
    useEffect(() => {
        collectionListing();
    }, [paymentStatus]);

    const openModal = async (id) => {
        setSpinner(true);
        const detailed = await transactionDetails({ transactionId: id })
        setSelectedTransaction(detailed?.result);
        setIsOpen(true)
        setSpinner(false);
    }

    return (
        <>
            <section className="content pb-5 pt-3">
                <div className="container-fluid">
                    <div className="new-graph">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dashboard-table mb-3 pb-3">
                                    <div className="table-responsive px-2">
                                        <div className="d-flex justify-content-between">
                                            <h4 className="pl-3">Transactions</h4>
                                            <div className='d-flex'>
                                                <select name="" id="" onChange={(e) => setPaymentStatus(e.target.value)}>
                                                    <option value="ALL">All</option>
                                                    <option value="SUCCESS">Success</option>
                                                    <option value="FAILED">Failed</option>
                                                    <option value="INITIATED">Initiated</option>
                                                </select>
                                                <div className="search-bar">
                                                    <RangePicker
                                                        className="ms-2"
                                                        defaultValue={[
                                                            startDate,
                                                            endDate,
                                                        ]}

                                                        onChange={(e) => changeDate(e)}
                                                    />
                                                    {/* <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box' /> */}
                                                </div>

                                            </div>

                                        </div>
                                        <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                                        <table id="example2" className="table table-hover">
                                            <tbody>
                                                {List.map((item, index) => {
                                                    return (
                                                        <tr className="point" key={index.toString()} onClick={() => openModal(item._id)}>
                                                            <td className="border-0">
                                                                <h3></h3>
                                                                <div className='text-center'>
                                                                    <h3>&nbsp;</h3>
                                                                    <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                    <h3 className='p-2'></h3>
                                                                </div>

                                                            </td>
                                                            <td className="border-0">
                                                                <div>
                                                                    <h3 className="mb-0">{item?.serviceId?.referenceId}</h3>
                                                                    <h5 className="mb-0">Service Ref Id</h5> </div>
                                                            </td>
                                                            <td className="border-0">
                                                                <div className="pl-3">
                                                                    <h3 className="mb-0">{item?.userId?.userName}</h3>
                                                                    <h5 className="mb-0">Customer</h5>
                                                                </div>
                                                            </td>
                                                            <td className="border-0">
                                                                <div className="pl-3">
                                                                    <h3 className="mb-0">{item?.collectorId ? item?.collectorId.userName : "N/A"}</h3>
                                                                    <h5 className="mb-0">Collector</h5>
                                                                </div>
                                                            </td>
                                                            <td className="border-0">
                                                                <div className="pl-3">
                                                                    <h3 className="mb-0">SLLE {item?.amount}</h3>
                                                                    <h5 className="mb-0">Amount</h5>
                                                                </div>
                                                            </td>
                                                            <td className="border-0">
                                                                <div>
                                                                    <h3 className="mb-0">{item.amountStatus}</h3>
                                                                    <h5 className="mb-0">Amount Status</h5> </div>
                                                            </td>
                                                            <td className="border-0">
                                                                <div className="pl-3">
                                                                    <h3 className="mb-0">{moment(item?.serviceId?.startDateTime).format('YYYY-MM-DD')}</h3>
                                                                    <h5 className="mb-0">Service Date</h5>
                                                                </div>
                                                            </td>
                                                            <td className="border-0">
                                                                <div>
                                                                    <h3 className="mb-0">{item.paymentStatus}</h3>
                                                                    <h5 className="mb-0">Payment Status</h5> </div>
                                                            </td>
                                                            <td className="border-0"><i className="fa fa-angle-right" aria-hidden="true"></i> </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div>
                                            {List.length ? (
                                                <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                            ) : (<></>)}
                                            {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Dialog open={isOpen}>
                <DialogTitle>Transaction Details</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className="col-md-6">
                            Service Ref Id :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.serviceId?.referenceId}
                        </div>
                        <div className="col-md-6">
                            Service Date :
                        </div>
                        <div className="col-md-6">
                            {moment(selectedTransaction?.serviceId?.startDateTime).format("YYYY-MM-DD")}
                        </div>
                        <div className="col-md-6">
                            Amount :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.amount}
                        </div>
                        <div className="col-md-6">
                            Payment Type :
                        </div>
                        <div className="col-md-6">
                            Orange Money
                        </div>
                        <div className="col-md-6">
                            Payment Ref ID :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.txnId}
                        </div>
                        <div className="col-md-6">
                            Request Type :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.serviceId?.trashPickupType}
                        </div>
                        <div className="col-md-6">
                            Request Date :
                        </div>
                        <div className="col-md-6">
                            {moment(selectedTransaction?.createdAt).format("YYYY-MM-DD")}
                        </div>
                        <div className="col-md-6">
                            Customer Name :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.userId?.userName}
                        </div>
                        <div className="col-md-6">
                            Collector Name :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.collectorId?.userName}
                        </div>
                        <div className="col-md-6">
                            Youth Group :
                        </div>
                        <div className="col-md-6">
                            {selectedTransaction?.youthGroupName}
                        </div>
                    </div>
                </DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >
                        Close
                    </button>
                </div>
            </Dialog>
        </>

    )
}

export default Transactions;