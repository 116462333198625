/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Storage } from "../../Helpers/appResource";
import { toast } from "react-toastify";
import { getProfile, editProfile } from "../../Services/api";
import { useNavigate } from "react-router-dom";

const AccountInfo = () => {
  let initialState = {
    userName: "",
    fullName: "",
    email: "",
    organization: "",
  };
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getProfile().then((profile) => {
      if (profile.code == 401) {
        navigate("/")
      }
      profile.code === 200
        ? setState(profile.result)
        : toast.error(profile.message);
    });
  }, []);

  const onChangeHandle = (event) => {
    setState((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const updateAccountInfo = () => {
    if (!state.userName || !state.userName.trim())
      return toast.error(Storage.USERNAME_REQUIRED);
    if (!state.fullName || !state.fullName.trim())
      return toast.error(Storage.FULL_NAME_REQUIRED);
    setSpinner(true);
    editProfile(state).then((data) => {
      setSpinner(false);
      if (data.code == 401) {
        navigate("/")
      }
      data.code === 200
        ? toast.success(Storage.ACCOUNT_INFO_UPDATED)
        : toast.error(data.message);
    })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <div className="card-body">
      <form>
        <div className="row gx-3 mb-3">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Username </label>
            <input
              name="userName"
              className="form-control"
              type="text"
              placeholder="Enter your username"
              value={state.userName}
              onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div>
        <div className="row gx-3 mb-3">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Full Name</label>
            <input
              name="fullName"
              className="form-control"
              type="text"
              placeholder="Enter your first name"
              value={state.fullName}
              onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div>
        <div className="row gx-3 mb-3">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Email address</label>
            <input
              name="email"
              className="form-control"
              type="email"
              placeholder="Enter your email address"
              disabled={true}
              value={state.email}
            />
          </div>
        </div>
        <div className="row gx-3 mb-3">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Role</label>
            <input
              className="form-control"
              value="Super Admin"
              type="text"
              placeholder="Enter your organization name"
              disabled={true}
              onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div>
        <div className="row gx-3 mb-3">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Organization name (optional)</label>
            <input
              name="organization"
              className="form-control"
              type="text"
              placeholder="Enter your organization name"
              value={state.organization}
              onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => updateAccountInfo()}
        >{(spinner ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}
          Update Info
        </button>
      </form>
    </div>
  );
};

export default AccountInfo;
