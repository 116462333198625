import moment from "moment-timezone";
/* eslint-disable array-callback-return */
export const mergeCollectors = (data) => {
    let name = "";
    data.map((data) => {
        name += `${data.userName},`
    });
    return name.split(',').slice(0, 3).join(",").slice(0, -1);
}

export const manageSegregatedList = (data) => {
    var result = [];
    switch (data.type) {
        case "WEEK":
            data.list.map((i) => {
                let forPush = {
                    Plastic: 0,
                    Organic: 0,
                    Mixed: 0,
                    Metal: 0
                }
                i.counts.map((item) => {
                    switch (item.name.labelName) {
                        case "Plastic":
                            forPush.Plastic = item.count;
                            break
                        case "Organic":
                            forPush.Organic = item.count;
                            break
                        case "Mixed":
                            forPush.Mixed = item.count;
                            break
                        case "Metals":
                            forPush.Metal = item.count;
                            break
                    }
                })
                forPush.name = moment(i.date).tz('utc').format("ddd");
                result.push(forPush)
            })
            break;
        case "MONTH":
            data.list.map((i) => {
                let forPush = {
                    Plastic: 0,
                    Organic: 0,
                    Mixed: 0,
                    Metal: 0
                }
                i.counts.map((item) => {
                    switch (item.name.labelName) {
                        case "Plastic":
                            forPush.Plastic = item.count;
                            break
                        case "Organic":
                            forPush.Organic = item.count;
                            break
                        case "Mixed":
                            forPush.Mixed = item.count;
                            break
                        case "Metals":
                            forPush.Metal = item.count;
                            break
                    }
                })
                forPush.name = moment(i.date).tz('utc').format("D MMM");
                result.push(forPush)
            })
            break;
        case "YEAR":
            data.list.map((i) => {
                let forPush = {
                    Plastic: 0,
                    Organic: 0,
                    Mixed: 0,
                    Metal: 0
                }
                i.counts.map((item) => {
                    switch (item.name.labelName) {
                        case "Plastic":
                            forPush.Plastic = item.count;
                            break
                        case "Organic":
                            forPush.Organic = item.count;
                            break
                        case "Mixed":
                            forPush.Mixed = item.count;
                            break
                        case "Metals":
                            forPush.Metal = item.count;
                            break
                    }
                })
                forPush.name = moment(i.date).tz('utc').format("MMM");
                result.push(forPush)
            })
            break;
    }
    return result
}