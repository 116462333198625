/* eslint-disable react-hooks/exhaustive-deps */
('use Strict');
import React, { useEffect, useState } from 'react';
import { collectionRequest } from '../../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../../Helpers/appResource';
import { Set, Get } from '../../../Helpers/localStorage';
import { useNavigate } from "react-router-dom";



const Requests = (props) => {
    const navigate = useNavigate();
    let timerCount = null;
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [filterIds, setFilterIds] = useState({});

    useEffect(()=>{
        Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, '')
    },[])

    useEffect(()=>{
        const projectId = window.location.pathname.split(':');

        props.type == "USER"? setFilterIds({userId:projectId[1]}) : setFilterIds({collectorId: projectId[1]});
    },[props.type])


    useEffect(() => {
        setPageNumber(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : pageNumber);
        setSearch(Get(Storage.SEARCH));
        collectionListing();
    }, [pageNumber, search, filterIds]);



    const collectionListing = async () => {
        if(!Object.keys(filterIds).length) return ;
        setSpinner(true);
        setRecordCheck(1);
        const listing = await collectionRequest({ pageNumber: pageNumber, searchKey: search, filterIds: filterIds });
        if (listing.code == 401) {
            navigate("/");
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setCount(Math.ceil(listing.count / 10));
    }

    const detailPage = (id) => { Set(Storage.PAGE_NUMBER, pageNumber); Set(Storage.SEARCH, search); navigate(`/collection-details/:${id}`) };
    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search) };


    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000);
    }

    return (
        <>
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="new-graph">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dashboard-table mb-3 pb-3">
                                        <div className="table-responsive px-2">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3">Job Request</h4>
                                                <div className="search-bar">
                                                    <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box' />
                                                </div>
                                            </div>
                                            <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                                            <table id="example2" className="table table-hover">
                                                <tbody>
                                                    {List.map((item, index) => {
                                                        return (
                                                            <tr className="point" key={index.toString()} onClick={() => detailPage(item._id)}>
                                                                {/*<tr className="point" key={index.toString()} >*/}
                                                                <td className="border-0">
                                                                    <h3></h3>
                                                                    <div className='text-center'>
                                                                        <h3>&nbsp;</h3>
                                                                        <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                        <h3 className='p-2'></h3>
                                                                    </div>

                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.referenceId}</h3>
                                                                        <h5 className="mb-0">Reference Id</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    {/* <div className=""><img src="dist/images/gwen.png" alt=""></img></div> */}
                                                                    <div className="pl-3">
                                                                        <h3 className="mb-0">{item?.userId ? item?.userId?.userName : 'N/A'}</h3>
                                                                        <h5 className="mb-0">User Name</h5>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.collectorId ? item?.collectorId?.userName : 'N/A'}</h3>
                                                                        <h5 className="mb-0">Collector Name</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.wasteQuantity}{'kg/lbs'}</h3>
                                                                        <h5 className="mb-0">Waste Quantity</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.status}</h3>
                                                                        <h5 className="mb-0">Job Status</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div>
                                                                        <h3 className="mb-0">{item.paymentStatus}</h3>
                                                                        <h5 className="mb-0">Payment Status</h5> </div>
                                                                </td>
                                                                <td className="border-0"><i className="fa fa-angle-right" aria-hidden="true"></i> </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div>
                                                {List.length ? (
                                                    <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                                ) : (<></>)}
                                                {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
        </>

    )
}

export default Requests;