/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { getCollectorDetails } from '../../Services/api';
import { toast } from 'react-toastify';
import "../../style/css/style.css"
import TabsWrap from './common/TabsWrap';



const CollectorDetails = () => {
    let navigate = useNavigate();
    const [user, setDetails] = useState([]);
    const dataFetchedRef = useRef(false);
    const collectorTabs = ['REQUEST','TRANSACTIONS']
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        userDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const projectId = window.location.pathname.split(':');
    if (!projectId[1]) return navigate('/collector', { replace: true });

    async function userDetails() {
        const details = await getCollectorDetails({ "collectorId": projectId[1] });
        // eslint-disable-next-line eqeqeq
        if (details.code == 401) {
            navigate("/");
        }
        details.code == 200 ? setDetails(details.result) : toast('User not found!')
    }


    return (
        <>
            <div className="dashboard content-wrapper">
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mb-4">

                                <div className="d-flex justify-content-between">
                                    <div className='d-flex align-items-center'>
                                        <a className="submit me-3 point" onClick={() => navigate('/collector')} ><i className='fa fa-long-arrow-left'></i></a>
                                        <h4>Collector Details</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="user-details">
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-12">
                                        <div className="user-img"><img src="/images/user.png" alt="" className='img-fluid' /></div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Phone Number :</p> </div>
                                    <div className="col-sm-8">
                                        <h6>{user.countryCode}{user.mobile}</h6>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Email-ID :</p>
                                    </div>
                                    <div className="col-sm-8">
                                        <h6>{user.email ? user.email : 'N/A'}</h6>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Address :</p>
                                    </div>
                                    <div className="col-sm-8">
                                        <h6>{user?.location?.address ? user?.location?.address : 'N/A'}</h6>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 py-2">
                                    <div className="col-sm-4">
                                        <p className='mb-0'>Youth Group :</p>
                                    </div>
                                    <div className="col-sm-8">
                                        <h6>{user?.youthGroup?.name ? user?.youthGroup?.name : 'N/A'}</h6>
                                    </div>
                                </div>
                            </div>
                            <TabsWrap type="COLLECTOR" tabs={collectorTabs} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default CollectorDetails;