/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
('use Strict');
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getUsers, changeUserStatus } from '../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../Helpers/appResource';
import { Set, Get } from '../../Helpers/localStorage';
import Dialog, { DialogTitle, DialogContent } from '@material/react-dialog';
import { toast } from 'react-toastify';
import exportFromJSON from 'export-from-json';

const UserList = () => {
    let timerCount = null;
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : 1);
    const [search, setSearch] = useState(Get(Storage.SEARCH) ? Get(Storage.SEARCH) : "");
    const [spinner, setSpinner] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userStatus, setUserStatus] = useState(true);
    const [dialog, setDialog] = useState("");
    const [buttonSpin, setButtonSpin] = useState(false);
    const [recordCheck, setRecordCheck] = useState(1);
    const [totalCount, setTotalCount] = useState(0);


    useEffect(() => {
        setPageNumber(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : pageNumber);
        setSearch(Get(Storage.SEARCH));
        userListing();
    }, [pageNumber, search]);


    const userListing = async () => {
        setRecordCheck(1)
        setSpinner(true);
        const listing = await getUsers({ pageNumber: pageNumber, searchKey: search });
        if (listing.code == 401) {
            navigate('/');
        }
        if (listing.count == 0) {
            setRecordCheck(0);
        }
        setSpinner(false);
        setList(listing.result);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }

    const getUserId = (id) => { Set(Storage.PAGE_NUMBER, pageNumber); Set(Storage.SEARCH, search); navigate(`/userDetails/:${id}`) };
    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search); };


    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000);
    }

    const openConfirmDialog = (value, status) => {
        setUserId(value); setUserStatus(status === true ? false : true); setIsOpen(true);
        setDialog(status === true ? <p>{Storage.DEACTIVATE_USER}</p> : <p>{Storage.ACTIVATE_USER}</p>);
    };

    const updateStatus = async () => {
        setButtonSpin(true);
        const updateStatus = await changeUserStatus({ userId: userId, status: userStatus });
        if (updateStatus.code === 200) {
            userListing();
            setIsOpen(false);
            setButtonSpin(false);
            toast.success(Storage.STATUS_SUCCESS);
        } else { toast.error(updateStatus.message); }
    }
    const exportHandler = async ()=>{
        const listing = await getUsers({ pageNumber: 1, pageSize:totalCount, searchKey: search });
        const fileName = 'Users'
        const exportType =  exportFromJSON.types.csv
        const expertData = [];
        listing.result?.map((user)=>{
            expertData.push({
                Name : user.userName,
                mobile : user.mobile,
                Status : user.status,
                Address : user?.location?.address,
            });
        });

        exportFromJSON({ data : expertData, fileName, exportType })
    }
    return (
        <>
            <div className="dashboard">
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="new-graph">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dashboard-table mb-3 pb-3">
                                        <div className="table-responsive px-2">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3">Users</h4>
                                                <div class="buttons days-buttons me-1 offset-6">
                                                    <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                                </div>
                                                <div className="search-bar">
                                                    <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box' />
                                                </div>
                                            </div>
                                            <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />

                                            <table id="example2" className="table table-hover">
                                                <tbody>
                                                    {List.map((item, index) => {
                                                        return (
                                                            <tr className="point" key={index.toString()}>
                                                                <td className="border-0" onClick={() => getUserId(item._id)}>
                                                                    <h3></h3>
                                                                    <div className='text-center'>
                                                                        <h3>&nbsp;</h3>
                                                                        <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                        <h3 className='p-2'></h3>
                                                                    </div>

                                                                </td>
                                                                <td className="border-0" onClick={() => getUserId(item._id)}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className="profile-img me-1"><img src={(item.profileImage ? item.profileImage : "../../images/user.png")} alt="" className='img-fluid'></img></div>
                                                                        <div className="pl-3">
                                                                            <h3 className="mb-0">{item.userName ? item.userName : 'N/A'}</h3>
                                                                            <h5 className="mb-0">Name</h5> </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getUserId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.countryCode}{item.mobile}</h3>
                                                                        <h5 className="mb-0">Phone Number</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getUserId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.email ? item.email : 'N/A'}</h3>
                                                                        <h5 className="mb-0">Email-ID</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getUserId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.active ? "Activated" : "Deactivated"}</h3>
                                                                        <h5 className="mb-0">User Status</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => openConfirmDialog(item._id, item.active)}>
                                                                    <div className='text-center'>
                                                                        <h3 className="mb-0">
                                                                            {item.active ? (
                                                                                <img src="images/eye-open.svg" />) :
                                                                                (<img src="images/eye-close.svg" />)
                                                                            }
                                                                        </h3>
                                                                        <h5 className="mb-0">Change Status</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getUserId(item._id)}><i className="fa fa-angle-right" aria-hidden="true"></i> </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div>
                                                {List.length ? (
                                                    <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                                ) : (<></>)}
                                                {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/* confirmation dialogue popup model */}
            <Dialog open={isOpen}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>{dialog}</DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)}>Close</button>
                    <button type="button" className="btn btn-primary primary-color d-flex align-items-center" onClick={() => updateStatus()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}{userStatus ? "Activate" : "Deactivate"}</button>
                </div>
            </Dialog>
        </>

    )
}

export default UserList;