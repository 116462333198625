/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getCollectors, changeCollectorStatus, createCollectors, sendCredentials, updateCollectorDetails, deleteCollector } from '../../Services/api';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-spinner-material';
import { Storage } from '../../Helpers/appResource';
import { Set, Get } from '../../Helpers/localStorage';
import Modal from 'react-bootstrap/Modal';
import Dialog, { DialogTitle, DialogContent } from '@material/react-dialog';
import { toast } from 'react-toastify';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import exportFromJSON from 'export-from-json';

const Collector = () => {
    let timerCount = null
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [name, setName] = useState("");
    const [editName, setEditName] = useState("");
    const [email, setEmail] = useState("");
    const [editEmail, setEditEmail] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [editCountryCode, setEditCountryCode] = useState("");
    const [countryName, setCountryName] = useState("");
    const [editCountryName, setEditCountryName] = useState("");
    const [mobile, setMobile] = useState("");
    const [editMobile, setEditMobile] = useState("");
    const [pageNumber, setPageNumber] = useState(Get(Storage.PAGE_NUMBER) ? Get(Storage.PAGE_NUMBER) : 1);
    const [search, setSearch] = useState(Get(Storage.SEARCH) ? Get(Storage.SEARCH) : "");
    const [spinner, setSpinner] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [collectorId, setCollectorId] = useState(null);
    const [userStatus, setUserStatus] = useState(true);
    const [dialog, setDialog] = useState("");
    const [buttonSpin, setButtonSpin] = useState(false);
    const [show, setShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editCollectorId, setEditCollectorId] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteCollectorId, setDeleteCollectorId] = useState("");
    const [recordCheck, setRecordCheck] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        ProjectListing();
    }, [pageNumber, search]);

    async function ProjectListing() {
        setSpinner(true);
        setRecordCheck(1);
        const listing = await getCollectors({ pageNumber: pageNumber, searchKey: search });
        if (listing.code == 401) {
            navigate('/');
        }
        if (listing.count == 0) {
            setRecordCheck(0)
        }
        setSpinner(false);
        setList(listing.result);
        setTotalCount(listing.count);
        setCount(Math.ceil(listing.count / 10));
    }

    async function getCollectorId(id) {
        Set(Storage.PAGE_NUMBER, pageNumber); Set(Storage.SEARCH, search); navigate(`/collectorDetails/:${id}`);
    }
    const handleChange = (event, value) => { setPageNumber(value); Set(Storage.PAGE_NUMBER, value); Set(Storage.SEARCH, search); };

    const searchEvent = (value) => {
        if (timerCount != null) clearTimeout(timerCount);
        timerCount = setTimeout(() => { setSearch(value); setPageNumber(1); Set(Storage.PAGE_NUMBER, 1); Set(Storage.SEARCH, value); }, 1000)
    }

    const openConfirmDialog = (value, status) => {
        setCollectorId(value); setUserStatus(status === true ? false : true); setIsOpen(true);
        setDialog(status === true ? <p>{Storage.DEACTIVATE_COLLECTOR}</p> : <p>{Storage.ACTIVATE_COLLECTOR}</p>);
    };

    const updateStatus = async () => {
        setButtonSpin(true);
        const updateStatus = await changeCollectorStatus({ collectorId: collectorId, status: userStatus });
        if (updateStatus.code == 401) {
            navigate("/");
        }
        if (updateStatus.code === 200) {
            ProjectListing(); setIsOpen(false); setButtonSpin(false);
            toast.success(Storage.STATUS_SUCCESS);
        } else { toast.error(updateStatus.message); }
    }

    const openNew = () => { setShow(true); }
    const closeNew = () => setShow(false);
    const setMobileNumber = (e, number, country) => {
        setMobile(number);
        setCountryName(country.iso2.toUpperCase());
        setCountryCode(`+${country.dialCode}`)
    }
    const setEditMobileNumber = (e, number, country) => {
        setEditMobile(number ? number : 0);
        setEditCountryName(country?.iso2?.toUpperCase());
        setEditCountryCode(`+${country?.dialCode}`)
    }
    const randomStr = (len, arr) => {
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }


    const addCollectors = async () => {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!name.trim()) return toast.error(Storage.USERNAME_REQUIRED);
        // if (!email.trim()) return toast.error(Storage.EMAIL_REQUIRED);
        if (!email.match(validRegex)) return toast.error(Storage.EMAIL_NOT_VALID);
        if (!mobile.length) return toast.error(Storage.CONTACT_NUMBER_REQUIRED);
        if (!countryCode.length) return toast.error(Storage.SELECT_COUNTRY);
        if (!countryName.length) return toast.error(Storage.SELECT_COUNTRY);
        // if (!password.trim()) return toast.error(Storage.NEW_PASSWORD_REQUIRED);
        // if (!confirmPassword.trim()) return toast.error(Storage.CONFIRM_PASSWORD_REQUIRED);
        // if (password.trim().length < 6 || password.trim().length > 16) return toast.error(Storage.PASSWORD_VALIDATION);
        // if (password.trim() != confirmPassword.trim()) return toast.error(Storage.PASSWORD_NOT_MATCHED);
        setButtonSpin(true);
        const addCollector = await createCollectors({
            userName: name,
            countryCode: countryCode,
            countryName: countryName,
            mobile: mobile,
            email: email.toLowerCase().trim(),
            password: randomStr(9, '12345678abcdefghijkl')
        });
        setButtonSpin(false);
        if (addCollector.code == 401) {
            navigate('/');
        }
        if (addCollector.code === 200) {
            closeNew();
            ProjectListing()
            toast.success(addCollector.message);
        } else { toast.error(addCollector.message) }
    }

    const sendPassword = async (data) => {
        const send = await sendCredentials({ collectorId: data });
        if (send.code === 200) {
            toast.success("Password sent to the selected Collector");
        } else {
            toast.error(send.message)
        }
    }

    const openEdit = (item) => {
        setEditCountryName(item.countryName)
        setEditCollectorId(item._id);
        setEditName(item.userName);
        setEditEmail(item.email);
        setEditCountryCode(item.countryCode);
        setEditMobile(item.mobile);
        setEditModal(true);
    }
    const editCollector = async () => {
        setButtonSpin(true);
        const res = await updateCollectorDetails({
            id: editCollectorId,
            userName: editName,
            email: editEmail
        })
        if (res.code == 200) {
            toast.success(res.message);
            ProjectListing();
            setEditModal(false);
            setButtonSpin(false);
        } else {
            toast.error(res.message);
            setButtonSpin(false);

        }
    }

    const deleteCollectorById = async () => {
        setButtonSpin(true);
        console.log(deleteCollectorId)
        const res = await deleteCollector({ id: deleteCollectorId })
        if (res.code == 200) {
            toast.success(res.message);
            ProjectListing();
            setIsDeleteModal(false);
            setButtonSpin(false);
        } else {
            toast.error(res.message);
            setIsDeleteModal(false)
            setButtonSpin(false);

        }
    }

    const exportHandler = async ()=>{
        const listing = await getCollectors({ pageNumber: 1, pageSize:totalCount, searchKey: search });
        const fileName = 'collectors'
        const exportType =  exportFromJSON.types.csv
        const expertData = [];
        listing.result?.map((user)=>{
            expertData.push({
                Name : user.userName,
                mobile : user.mobile,
                Email : user.email,
                Status : user.status,
            });
        });
        exportFromJSON({ data : expertData, fileName, exportType })
    }
    return (
        <>
            <div className="dashboard content-wrapper">
                <section className="content pb-5 pt-3">
                    <div className="container-fluid">
                        <div className="new-graph">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dashboard-table mb-3 pb-3">
                                        <div className="table-responsive">
                                            <div className='d-flex flex-row-reverse mb-1'>

                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3">Collectors</h4>
                                                <div class="buttons days-buttons me-1 offset-4">
                                                    <button className={"mx-1 "} onClick={() => exportHandler()}>Export To Excel</button>
                                                </div>
                                                <div className="search-bar d-flex align-items-center">
                                                    <input type="text" onChange={(event) => searchEvent(event.target.value)} placeholder='Search' className='search-box me-3' />
                                                    <div className="add-btn"><button className="btn" onClick={() => openNew()}>Add Collector</button></div>
                                                </div>
                                            </div>
                                            <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />
                                            <table id="example2" className="table table-hover">
                                                <tbody>
                                                    {List.map((item, index) => {
                                                        return (
                                                            <tr className="point" key={index.toString()} >
                                                                <td className="border-0" onClick={() => getCollectorId(item._id)}>
                                                                    <h3></h3>
                                                                    <div className="pl-3">
                                                                        <h3>&nbsp;</h3>
                                                                        <h3 className="mb-0">{((pageNumber - 1) * 10) + index + 1}</h3>
                                                                        <h3 className='p-2'></h3>
                                                                    </div>

                                                                </td>
                                                                <td className="border-0" onClick={() => getCollectorId(item._id)}>
                                                                    <div className='d-flex'>
                                                                        <div className="profile-img"><img src={(item.profileImage ? item.profileImage : "../../images/user.png")} alt="" className='img-fluid'></img></div>
                                                                        <div className="pl-3">
                                                                            <h3 className="mb-0">{item.userName ? item.userName : 'N/A'}</h3>
                                                                            <h5 className="mb-0">Name</h5> </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getCollectorId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.countryCode}{item.mobile}</h3>
                                                                        <h5 className="mb-0">Phone Number</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getCollectorId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.email ? item.email : 'N/A'}</h3>
                                                                        <h5 className="mb-0">Email-ID</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => getCollectorId(item._id)}>
                                                                    <div>
                                                                        <h3 className="mb-0">{item.active ? "Activated" : "Deactivated"}</h3>
                                                                        <h5 className="mb-0">User Status</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => openEdit(item)}>
                                                                    <div >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                        </svg>
                                                                        <h5 className="mb-0">Edit</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    <div className='text-center'>
                                                                        <img src='images/Delete-button.svg' onClick={() => { setIsDeleteModal(true); setDeleteCollectorId(item._id) }}></img>
                                                                        <h5 className="mb-0">delete</h5> </div>
                                                                </td>
                                                                <td className="border-0" onClick={() => openConfirmDialog(item._id, item.active)}>
                                                                    <div className='text-center'>
                                                                        <h3 className="mb-0 text-center">
                                                                            {item.active ? (
                                                                                <img src="images/eye-open.svg" />) :
                                                                                (<img src="images/eye-close.svg" />)
                                                                            }

                                                                        </h3>
                                                                        <h5 className="mb-0">Change Status</h5> </div>
                                                                </td>
                                                                <td className="border-0">
                                                                    {/* <i className="fa fa-angle-right" aria-hidden="true"></i> */}
                                                                    <button style={{ width: 120 }} onClick={() => sendPassword(item._id)} className='btn send-credentials'>Send Credentials</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <div >
                                                {List.length ? (
                                                    <Pagination style={{ display: 'block', float: 'right' }} onChange={handleChange} page={pageNumber} count={count} />
                                                ) : (<></>)}
                                                {recordCheck === 0 ? (<p style={{ textAlign: 'center', color: 'red' }}>No data found!</p>) : (<></>)}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 offset-md-5"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal fade" id="addGroupModal" tabIndex="-1" aria-labelledby="addGroupModalLabel" aria-hidden="true">
                    <Modal className='addCollectorModel' style={{ top: 80 }} show={show} backdrop="static" keyboard={false}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="addGroupModalLabel">Add Collector</h1>
                                <button type="button" className="btn-close" onClick={() => closeNew()} data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">User Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="User Name" defaultValue={editName} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                                    <input type="text" className="form-control" id="email" placeholder="email" defaultValue={editEmail} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Contact Number</label>
                                    {/* <PhoneInput placeholder="Enter phone number" defaultCountry="US" onChange={(e) => setMobileNumber(e)} /> */}
                                    <IntlTelInput preferredCountries={['SL']} containerClassName="intl-tel-input" separateDialCode="true" inputClassName="form-control" onPhoneNumberChange={(e, mobile, country) => setMobileNumber(e, mobile, country)} />
                                </div>
                                {/* <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Confirm Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div> */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => closeNew()} data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary primary-color d-flex align-items-center" disabled={buttonSpin} onClick={() => addCollectors()}>
                                    {(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Add</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="modal fade" id="editCollector" tabIndex="-1" aria-labelledby="editCollectorModalLabel" aria-hidden="true">
                    <Modal className='editCollector' style={{ top: 80 }} show={editModal} backdrop="static" keyboard={false}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="editCollectorModalLabel">Edit Collector</h1>
                                <button type="button" className="btn-close" onClick={() => setEditModal(false)} data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">User Name</label>
                                    <input defaultValue={editName} type="text" className="form-control" id="name" placeholder="User Name" onChange={(e) => setEditName(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                                    <input defaultValue={editEmail} type="text" className="form-control" id="email" placeholder="email" onChange={(e) => setEditEmail(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Contact Number</label>
                                    {/* <PhoneInput placeholder="Enter phone number" defaultCountry="US" onChange={(e) => setMobileNumber(e)} /> */}
                                    <IntlTelInput placeholder={editMobile} preferredCountries={[editCountryName]} containerClassName="intl-tel-input" separateDialCode="true" inputClassName="form-control" onPhoneNumberChange={(e, mobile, country) => setEditMobileNumber(e, mobile, country)} disabled={true} />
                                </div>
                                {/* <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Confirm Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div> */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setEditModal(false)} data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary primary-color d-flex align-items-center" disabled={buttonSpin} onClick={() => editCollector()}>
                                    {(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Update</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div >

            {/* confirmation dialogue popup model */}
            < Dialog open={isOpen} >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>{dialog}</DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >Close</button>
                    <button type="button" className="btn btn-primary primary-color d-flex align-items-center" onClick={() => updateStatus()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}{userStatus ? "Activate" : "Deactivate"}</button>
                </div>
            </Dialog >
            < Dialog open={isDeleteModal} >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>Are you sure you want to delete collector</DialogContent>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setIsDeleteModal(false)} >Close</button>
                    <button type="button" className="btn btn-danger primary-color d-flex align-items-center" onClick={() => deleteCollectorById()}>{(buttonSpin ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}Delete</button>
                </div>
            </Dialog >
        </>
    )
}

export default Collector;