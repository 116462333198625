/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  getWastePrice,
  createWastePrice,
  editWastePrice,
} from "../../Services/api";
import { toast } from "react-toastify";
import { Storage } from "../../Helpers/appResource";
import Pagination from "@material-ui/lab/Pagination";
import Spinner from "react-spinner-material";
import Modal from "react-bootstrap/Modal";

const PriceManagement = () => {
  const [minVolume, setMinVol] = useState(0);
  const [maxVolume, setMaxVol] = useState(0);
  const [price, setPrice] = useState(0);
  const [locationType, setLocationType] = useState("HOUSEHOLD");
  const [List, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const [buttonSpin, setButtonSpin] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setEdit] = useState(false);
  const [priceId, setPriceId] = useState(null);
  const [searchAddress , setSearchAddress] = useState("");

  useEffect(() => {
    allWastePrice();
  }, [searchAddress]);

  const allWastePrice = async () => {
    setSpinner(true);
    const listing = await getWastePrice({locationType:searchAddress});
    setSpinner(false);
    setList(listing.result);
    setCount(Math.ceil(listing.count / 10));
  };

  const addPrice = async () => {
    if (!minVolume) return toast.error(Storage.MIN_VOL_REQUIRED);
    if (!maxVolume) return toast.error(Storage.MAX_VOL_REQUIRED);
    if (!price) return toast.error(Storage.PRICE_REQUIRED);
    if (!locationType) return toast.error(Storage.LOCATION_TYPE_REQUIRED);

    setButtonSpin(true);
    createWastePrice({ minVolume, price, maxVolume, locationType }).then(
      (data) => {
        setButtonSpin(false);
        if (data.code === 200) {
          closeNew();
          toast.success(data.message);
          allWastePrice();
        } else {
          toast.error(data.message);
        }
      }
    );
  };

  const handleChange = (event, value) => setPageNumber(value);

  const openNew = () => {
    setShow(true);
  };

  const closeNew = () => setShow(false);

  const openEdit = (value) => {
    setMinVol(value.minVolume);
    setMaxVol(value.maxVolume);
    setPrice(value.price);
    setLocationType(value.locationType);
    setPriceId(value._id);
    setEdit(true);
  };

  const updatePrice = async () => {
    if (!minVolume) return toast.error(Storage.MIN_VOL_REQUIRED);
    if (!maxVolume) return toast.error(Storage.MAX_VOL_REQUIRED);
    if (!price) return toast.error(Storage.PRICE_REQUIRED);
    if (!locationType) return toast.error(Storage.LOCATION_TYPE_REQUIRED);
    setButtonSpin(true);
    editWastePrice({ minVolume, price, maxVolume, locationType, priceId }).then(
      (data) => {
        setButtonSpin(false);
        if (data.code === 200) {
          closeEdit();
          toast.success(data.message);
          allWastePrice();
        } else {
          toast.error(data.message);
        }
      }
    );
  };

  const closeEdit = () => {
    setEdit(false);
    setMinVol(0);
    setMaxVol(0);
    setPrice(0);
    setLocationType("HOUSEHOLD");
  };

  return (
    <>
    <div className="dashboard content-wrapper">
      <section className="content pb-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-table mb-3 pb-3">
                <div className="table-responsive">
                  <div className="d-flex justify-content-between">
                    <h4 className="pl-3">Price Management</h4>
                    <h4 className="pl-3"> 
                    <select onChange={(e)=> setSearchAddress(e.target.value)} className="form-select" aria-label="Default select example" > 
                        <option value="" > All </option>
                        <option value="HOUSEHOLD" > Household </option>
                        <option value="BUSINESS" > Business </option>
                    </select>
                    </h4>
                    <div className="add-btn">
                      <button className="btn" onClick={()=> openNew()}> {" "} Add Price{" "} </button>
                    </div>
                  </div>
                  <Spinner className="spinner-style" radius={60} color={"#1387c2"} visible={spinner} />
                  <table id="example2" className="table table-hover">
                    <tbody> {" "} {List.map((item, index) => { return ( <tr className="point" key={index.toString()}>
                        <td className="border-0">
                          <div className="pl-3">
                            <h3 className="mb-0">{item.minVolume}</h3>
                            <h5 className="mb-0">Min Vol</h5>{" "}
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="pl-3">
                            <h3 className="mb-0">{item.maxVolume}</h3>
                            <h5 className="mb-0">Max Vol</h5>{" "}
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="pl-3">
                            <h3 className="mb-0">{item.price}</h3>
                            <h5 className="mb-0">Price</h5>{" "}
                          </div>
                        </td>
                        <td className="border-0">
                          <div className="pl-3">
                            <h3 className="mb-0" data-toggle="tooltip" data-placement="top" title=""> {" "} {item.locationType}{" "} </h3>
                            <h5 className="mb-0">Location Type</h5>{" "}
                          </div>
                        </td>
                        <td className="border-0" onClick={()=> openEdit(item)} > {" "} <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg>
                            <h5 className="mb-0">View/Edit</h5>{" "}
                          </div>
                        </td>
                      </tr> ); })}{" "} </tbody>
                  </table>
                  <div> {" "} {List.length ? (
                    <Pagination style={{ display: "block", float: "right" }} onChange={handleChange} page={pageNumber} count={count} /> ) : ( <p style={{ textAlign: "center", color: "red" }}> {" "} No data found!{" "} </p> )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 offset-md-5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" id="addGroupModal" tabIndex="-1" aria-labelledby="addGroupModalLabel" aria-hidden="true">
        <Modal show={show} backdrop="static" keyboard={false}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addGroupModalLabel"> {" "} Add Price{" "} </h1>
              <button type="button" className="btn-close" onClick={()=> closeNew()} data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Min Volume{" "} </label>
                <input type="number" min="1" className="form-control" id="name" placeholder="0" onChange={(e)=> setMinVol(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Max Volume{" "} </label>
                <input type="number" min="1" className="form-control" id="name" placeholder="10" onChange={(e)=> setMaxVol(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Price{" "} </label>
                <input type="number" min="1" className="form-control" id="name" placeholder="0.00" onChange={(e)=> setPrice(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Location Type{" "} </label>
                <select onChange={(e)=> setLocationType(e.target.value)} className="form-select" aria-label="Default select example" > <option value="HOUSEHOLD">Household</option>
                  <option value="BUSINESS">Business</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={()=> closeNew()} data-bs-dismiss="modal" > {" "} Close{" "} </button>
              <button type="button" className="btn btn-primary primary-color d-flex align-items-center" disabled={buttonSpin} onClick={()=> addPrice()} > {" "} {buttonSpin ? ( <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> ) : ( "" )}{" "} Add{" "} </button>
            </div>
          </div>
        </Modal>
      </div> {/* edot youth group functionality model */} <div className="modal fade" aria-labelledby="addGroupModalLabel" aria-hidden="true">
        <Modal show={showEdit} onHide={closeEdit} backdrop="static" keyboard={false}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addGroupModalLabel"> {" "} Edit Price{" "} </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Min Volume{" "} </label>
                <input type="number" min="1" value={minVolume} className="form-control" placeholder="0" onChange={(e)=> setMinVol(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Max Volume{" "} </label>
                <input type="number" min="1" value={maxVolume} className="form-control" placeholder="10" onChange={(e)=> setMaxVol(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Price{" "} </label>
                <input type="number" min="1" value={price} className="form-control" placeholder="0.00" onChange={(e)=> setPrice(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label"> {" "} Location Type{" "} </label>
                <select disabled={true} onChange={(e)=> setLocationType(e.target.value)} className="form-select" aria-label="Default select example" > <option value="HOUSEHOLD" selected={locationType==="HOUSEHOLD" }> Household </option>
                  <option value="BUSINESS" selected={locationType==="BUSINESS" }> Business </option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={()=> closeEdit()} data-bs-dismiss="modal" > {" "} Close{" "} </button>
              <button type="button" className="btn btn-primary d-flex align-items-center" disabled={buttonSpin} onClick={()=> updatePrice()} > {" "} {buttonSpin ? ( <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> ) : ( "" )}{" "} Update{" "} </button>
            </div>
          </div>
        </Modal>
      </div>
    </div> undefined undefined
  </>
  );
};

export default PriceManagement;
